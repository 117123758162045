





























import Vue from 'vue'
import Content from '../components/Content.vue'
import SignatureToolbar from '../components/SignatureToolbar.vue'
import PDFViewer from '../components/PDFViewer.vue'
import { get, patch } from '@/services/api'

export default Vue.extend({
  components: {
    Content,
    SignatureToolbar,
    PDFViewer
  },
  data () {
    return {
      pdf: null,
      numPages: 1,
      src: undefined,
      currentPage: 1,
      numberOfSignatures: 1,
      signatures: [],
      signaturePlaceholderComponents: []
    }
  },
  mounted () {
    const url = this.$route.params.template ? `/templates/${this.$route.params.id}` : `/pdfs/${this.$route.params.id}`

    get(url).then(response => {
      this.pdf = response.data
    }).catch((e) => {
      this.requestFailed = true
      console.log(e)
    })
  },
  methods: {
    deleteSignature (e: { recipientIndex: number; signatureIndex: number; signature: Record<string, unknown> }) {
      //* Take the two indexes from the event object
      const { recipientIndex, signatureIndex, signature } = e

      const matchingId = this.signaturePlaceholderComponents.findIndex(placeholderComponent => placeholderComponent.uniqueId === signature.uniqueId)

      if (matchingId === -1) {
        this.$notify({
          group: 'main',
          title: 'Error Deleting Signature',
          text: 'There was an error deleting the signature',
          type: 'error'
        })
      } else {
        //* Delete the placeholder component and delete it from the array
        this.signaturePlaceholderComponents[matchingId].$el.parentNode.removeChild(this.signaturePlaceholderComponents[matchingId].$el)
        this.signaturePlaceholderComponents.splice(matchingId, 1)
      }

      //! Deincrementing the number will cause Vue to delete the newest Placeholder (which is ALWAYS the one in the toolbar)
      // this.numberOfSignatures--

      //* Remove the signature from the array
      this.signatures[recipientIndex].inputs.splice(signatureIndex, 1)
    },
    saveCoordinates (e) {
      const interactJsElement = e.coords
      const recipient = e.currentRecipient

      if (!this.signatures[recipient.id]) {
        this.signatures[recipient.id] = {
          ...recipient,
          type: 'signer',
          inputs: []
        }
      }

      //* Find the correct input in the array to edit
      let indexOfSavedInput = this.signatures[recipient.id].inputs.findIndex(signature => signature.uniqueId === interactJsElement.placeholder.uniqueId)

      //* If this is the first time the item has been dragged/dropped, then it has JUST been created and should be added to the inputs array
      //* This lines makes the index the length of the current array (This item used to be pushed to the end but this way saves a few lines)
      indexOfSavedInput = indexOfSavedInput === -1 ? this.signatures[recipient.id].inputs.length : indexOfSavedInput

      //* Finally, save/overwrite to the array
      this.signatures[recipient.id].inputs[indexOfSavedInput] = {
        uniqueId: interactJsElement.placeholder.uniqueId,
        inputName: 'signature' + indexOfSavedInput,
        type: 'signature',
        position: {
          page: interactJsElement.page,
          x: interactJsElement.x,
          y: interactJsElement.y,
          width: 300,
          height: 150
        }
      }
    },

    newSignature (newSignaturePlaceholderComponent) {
      //* This function should really be called "Create new placeholder" as that's all it essentially does
      this.numberOfSignatures++
      //* Adds to the array of Vue components (this array is used to delete the actual DOM elements later)
      this.signaturePlaceholderComponents.push(newSignaturePlaceholderComponent)
    },
    nextPage () {
      if (this.currentPage < this.numPages) {
        this.currentPage++
      }
    },
    previousPage () {
      if (this.currentPage > 1) {
        this.currentPage--
      }
    },
    postSignatures () {
      //* When toolbar's "Done" button is pressed
      const data = {
        sequenced: !!this.$route.params.sequential,
        users: []
      }

      // Note: this.signatures should REALLY be called "users"
      for (const user in this.signatures) {
        data.users.push(this.signatures[user])
      }

      for (const user in this.$route.params.observers) {
        if (this.$route.params.template) {
          data.users.push({
            slug: this.$route.params.observers[user].slug,
            id: this.$route.params.observers[user].id,
            name: this.$route.params.observers[user].name,
            type: 'viewer'
          })
        } else {
          data.users.push({
            firstName: this.$route.params.observers[user].firstName,
            lastName: this.$route.params.observers[user].lastName,
            email: this.$route.params.observers[user].email,
            id: this.$route.params.observers[user].id,
            type: 'viewer'
          })
        }
      }

      const url = this.$route.params.template ? `/templates/${this.$route.params.id}` : `/pdfs/${this.$route.params.id}`

      patch({ positions: JSON.stringify(data) }, url).then(() => {
        if (this.$route.params.template) {
          alert(`Template ID is ${this.$route.params.id}. Slugs: ${data.users.map(u => u.slug).join(', ')}`)
        }
        this.$router.push('/')
      }).catch(err => {
        console.log(err)
        this.$notify({
          group: 'main',
          title: 'PDF Upload Error',
          text: 'There was an error uploading the PDF',
          type: 'error'
        })
      })
    }
  }
})
