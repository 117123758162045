

































































export default {
  name: 'ContentBox',
  props: {
    pdf: Object
  },
  data (): any {
    return {
      deleteMode: false
    }
  },
  methods: {
    goToRecipients: function () {
      this.$router.push({ name: 'recipients', params: { id: this.pdf.id } })
    },
    checkIfSigned: function (user, userDetails) {
      let result = false

      for (const i in userDetails.signedBy) {
        const signedUser = userDetails.signedBy[i]
        if (signedUser.id === user.id) result = true
      }

      return result
    },
    deletePdf: async function (id) : Promise<void> {
      this.$emit('delete-pdf', id)
    }
  },
  computed: {
    pdfTime: function (): string {
      const date = new Date(this.pdf.dateUploaded)
      return date.toLocaleTimeString('en-gb', { hour: '2-digit', minute: '2-digit' })
    },
    pdfDate: function (): string {
      const date = new Date(this.pdf.dateUploaded)
      return date.toLocaleDateString('en-gb')
    }
  }
}
