




















import Vue from 'vue'
import Content from '../components/Content.vue'
import Form from '../components/Form.vue'

export default Vue.extend({
  name: 'ResetPassword',
  data () {
    return {
      show2FA: false,
      alert: false,
      alertType: 'success',
      alertText: ''
    }
  },
  components: {
    Content,
    Form
  },
  methods: {
    onSubmit (values) {
      fetch(process.env.VUE_APP_BACKEND_HOST + '/auth/reset-password', { method: 'POST', credentials: 'include', body: values, headers: { 'Content-Type': 'application/json' } }).then((response) => {
        if (response.status === 201) {
          this.alertText = 'If an account is associated with the email provided, a link to reset your password will be sent to it.'
          this.alert = true
        }
      }
      )
    }
  }
})
