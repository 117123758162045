






































import SidenavItem from './Item.vue'
import { inject } from '@vue/composition-api'

/* ICONS */
import HomeIcon from './icons/Home.vue'
import SettingsIcon from './icons/Settings.vue'
import ArchivesIcon from './icons/Archives.vue'
import UploadIcon from './icons/Upload.vue'
import { useAuthStore } from 'pb-frontend-lib'

export default {
  name: 'SidenavItems',
  setup () {
    const authStore = useAuthStore()
    const open = inject('open')

    return { authStore, open }
  },
  components: {
    HomeIcon,
    SidenavItem,
    SettingsIcon,
    ArchivesIcon,
    UploadIcon
  },
  data: function () {
    return {
      lastBuildDate: ''
    }
  },
  mounted () {
    try {
      const date = new Date(config.date)
      this.lastBuildDate = date.toLocaleDateString() + ' - ' + date.toLocaleTimeString()
    } catch (error) {
      console.log(error)
      console.log('No "lastBuiltDate" found in Items.vue')
    }
  },
  computed: {
    loggedIn: function (): boolean {
      if (this.authStore.user) {
        return true
      }
      return false
    }
  }
}
