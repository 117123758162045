































import Vue from 'vue'
import PDFList from '../components/PDFList.vue'
import Content from '../components/Content.vue'
import Spinner from '../components/Spinner.vue'
import { get, deletes } from '@/services/api'
import { SignStatus } from '@/types/PDF'
import { useAuthStore } from 'pb-frontend-lib'
import { AxiosResponse } from 'axios'
import { determinePDFStatus } from '@/services/pdfUtility'

export default Vue.extend({
  components: {
    PDFList,
    Content,
    Spinner
  },
  setup () {
    const authStore = useAuthStore()

    return { authStore }
  },
  data () {
    return {
      loading: true,
      pdfs: [],
      templates: [],
      showTemplates: false
      // completed: [],
      // readyToSign: [],
      // needPositions: []
    }
  },
  computed: {
    completed: function () {
      return this.pdfs.filter((pdf) => {
        return pdf.status === SignStatus.Completed
      })
      // return this.pdfs.filter((pdf) => {
      //   return pdf.completed
      // })
    },
    needPositions: function () {
      return this.pdfs.filter((pdf) => {
        return pdf.status === SignStatus.NeedPositions
      })
      // return this.pdfs.filter((pdf) => {
      //   return !pdf.positions
      // })
    },
    waitingForOthers: function () {
      return this.pdfs.filter((pdf) => {
        return pdf.status === SignStatus.WaitingForOthers
      })
      // return this.pdfs.filter((pdf) => {
      //   let waiting = false
      //   const positions = JSON.parse(pdf.positions)
      //   if (positions.sequenced) {
      //     if (!pdf.completed) {
      //       const signableIds: Array<string | number> = this.csvToIds(pdf.signableBy)
      //       const index = signableIds.indexOf(this.$store.getters.user.id)
      //       const signedIds = this.csvToIds(pdf.signedBy)
      //       if (!signedIds[index]) {
      //         if (!signedIds[index - 1]) {
      //           waiting = true
      //         }
      //       } else {
      //         waiting = true
      //       }
      //     }
      //   } else {
      //     // Else not a sequenced PDF
      //     const signableIds: Array<string | number> = this.csvToIds(pdf.signableBy)
      //     const signedIds: Array<string | number> = this.csvToIds(pdf.signedBy)
      //     if (signableIds.length > signedIds.length) return true
      //   }
      //   return waiting
      // })
    },
    readyToSign: function () {
      return this.pdfs.filter((pdf) => {
        return pdf.status === SignStatus.ReadyToSign
      })
      // return this.pdfs.filter((pdf) => {
      //   const signedIds = this.csvToIds(pdf.signedBy)
      //   return !signedIds.includes(this.$store.getters.user.id) && !pdf.completed
      // })
    },
    unknown: function () {
      return this.pdfs.filter((pdf) => {
        return pdf.status === SignStatus.Unknown
      })
    }
  },
  methods: {
    async deletePdf (id) {
      try {
        const response: AxiosResponse = await deletes('/pdfs/' + id)

        if (response.status === 200) {
          this.$notify({
            group: 'main',
            title: 'PDF Deleted',
            text: 'The PDF has been successfully deleted',
            type: 'success'
          })
        }
      } catch (e) {
        if (e.response.status === 403) {
          this.$notify({
            group: 'main',
            title: 'Cannot Delete PDF',
            text: 'This PDF has already been signed by 1 or more user(s)',
            type: 'error'
          })
        }
      }

      this.load()
    },
    async load () {
      try {
        const resPdfs = await get('/pdfs')
        this.pdfs = resPdfs.data

        resPdfs.data.forEach(pdf => {
          determinePDFStatus(pdf, this.authStore.user.id)
        })

        const resTemplates = await get('/templates')
        this.templates = resTemplates.data

        this.loading = false

        // req.data.map((pdf) => {
        //   if (pdf.completed === true) {
        //     this.completed.push(pdf)
        //   } else if (!pdf.positions) {
        //     this.needPositions.push(pdf)
        //   } else {
        //     const signedIds = this.csvToIds(pdf.signedBy)
        //     if (!signedIds.includes(this.$store.getters.user.id)) {
        //       this.readyToSign.push(pdf)
        //     }
        //   }
        // })
      } catch (e) {
        console.log(e)
      }
    }
    // csvToIds (csv: string, delimiter = ','): Array<string | number> {
    //   const ids = []
    //   if (csv !== null) {
    //     csv.split(delimiter).map((value) => {
    //       try {
    //         ids.push(Number(value))
    //       } catch (error) {
    //         ids.push(value)
    //       }
    //     })
    //   }
    //   return ids
    // }
  },
  mounted () {
    this.load()
    const unsubscribe = this.authStore.$onAction(
      ({
        name // name of the action
      }) => {
        if (name === 'setCompany') this.load()
      }
    )
    // this.$store.subscribe((mutation, state) => {
    //   if (mutation.type === 'auth/setCompany') this.load()
    //   // More at: https://vuex.vuejs.org/api/#subscribe
    //   // console.log(mutation.type)
    //   // console.log(mutation.payload)
    // })
  }
})
