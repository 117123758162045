const set = function (key: string, value: string): any {
  console.log('Set ' + key + ' - Local Secure Storage')
  return localStorage.setItem(key, value)
}

const get = function (key: string): any {
  console.log('Get ' + key + ' - Local Secure Storage')
  return localStorage.getItem(key)
}

const clear = function (): any {
  console.log('Clear All - Local Secure Storage')
  localStorage.clear()
}

export default { set, get, clear }
export { set, get, clear }
