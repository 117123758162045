








import pdfjsLib from 'pdfjs-dist/build/pdf'
import PDFPage from '../components/PDFPage.vue'

pdfjsLib.GlobalWorkerOptions.workerSrc =
  'https://cdn.jsdelivr.net/npm/pdfjs-dist@2.2.228/build/pdf.worker.min.js'

export default {
  name: 'PdfViewer',
  components: {
    PDFPage
  },
  data (): unknown {
    return {
      thePDF: null,
      pagesLoaded: 0,
      numPages: 0,
      pdfSource: null
    }
  },
  props: {
    preview: {
      default: false,
      type: Boolean
    },
    url: {
      type: String
    }
  },
  created () : void {
    this.getPages()
  },
  methods: {
    async getPages () : Promise<void> {
      try {
        const loadingTask = pdfjsLib.getDocument(this.url)
        loadingTask.promise.then((pdf) => {
          this.pdfSource = pdf
          this.numPages = pdf.numPages
          if (this.preview === true) {
            this.numPages = 1
          }
        })
      } catch (error) {
        this.$notify({
          group: 'main',
          title: 'Could not load file.',
          text: 'There was an error loading the file.',
          type: 'error'
        })
      }
    },
    checkLoaded () : void {
      this.pagesLoaded++

      if (this.pagesLoaded === this.numPages) {
        this.$emit('finished-loading')
      }
    }
  }
}
