




















export default {
  props: {
    item: {
      type: Object
    },
    template: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    remove: function () {
      this.$emit('remove')
    }
  }
}

