import {
  TButton,
  TRichSelect,
  TCheckboxGroup,
  TToggle,
  TCard,
  TModal,
  TAlert,
  TInput,
  TInputGroup,
  TSelect,
  TRadio,
  TRadioGroup
} from 'vue-tailwind/dist/components'

const settings = {
  't-button': {
    component: TButton,
    props: {
      fixedClasses: 'block px-4 py-2 transition duration-100 ease-in-out focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50 disabled:opacity-50 disabled:cursor-not-allowed',
      classes: 'text-white bg-blue-500 border border-transparent shadow-sm rounded hover:bg-blue-600',
      variants: {
        secondary: 'text-gray-800 bg-white border border-gray-300 shadow-sm hover:text-gray-600',
        error: 'text-white bg-red-500 border border-transparent rounded shadow-sm hover:bg-red-600',
        success: 'text-white bg-green-500 border border-transparent rounded shadow-sm hover:bg-green-600',
        link: 'text-blue-500 underline hover:text-blue-600',
        disabled: 'bg-gray-100 border border-gray-100 text-gray-200 disabled:cursor-not-allowed rounded'
      }
    }
  },
  't-rich-select': {
    component: TRichSelect
  },
  't-select': {
    component: TSelect
  },
  't-checkbox-group': {
    component: TCheckboxGroup
  },
  't-toggle': {
    component: TToggle
  },
  't-card': {
    component: TCard
  },
  't-modal': {
    component: TModal
  },
  't-radio': {
    component: TRadio,
    props: {
      wrapped: true,
      classes: {
        label: 'text-sm uppercase mx-2 text-gray-700',
        input: 'text-blue-500 transition duration-100 ease-in-out border-gray-300 shadow-sm focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 focus:ring-offset-0  disabled:opacity-50 disabled:cursor-not-allowed transition duration-150 ease-in-out',
        inputWrapper: 'inline-flex',
        wrapper: 'flex items-center'
        // labelChecked: '',
        // inputWrapperChecked: '',
        // wrapperChecked: '',
      }
      // Variants and fixed classes in the same `object` format ...
    }
  },
  't-radio-group': {
    component: TRadioGroup
  },
  't-alert': {
    component: TAlert,
    props: {
      fixedClasses: {
        wrapper: 'flex items-center p-4 border-l-4 rounded shadow-sm mb-4',
        body: 'flex-grow',
        close: 'absolute relative flex items-center justify-center ml-4 flex-shrink-0 w-6 h-6 transition duration-100 ease-in-out rounded focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50',
        closeIcon: 'fill-current h-4 w-4'
      },
      classes: {
        wrapper: 'bg-blue-50 border-blue-500',
        body: 'text-blue-700',
        close: 'text-blue-500 hover:bg-blue-200'
      },
      variants: {
        danger: {
          wrapper: 'bg-red-50 border-red-500',
          body: 'text-red-700',
          close: 'text-red-500 hover:bg-red-200'
        },
        success: {
          wrapper: 'bg-green-50 border-green-500',
          body: 'text-green-700',
          close: 'text-green-500 hover:bg-green-200'
        }
      }
    }
  },
  't-input': {
    component: TInput,
    props: {
      fixedClasses: 'my-1 block w-full px-3 py-2 transition duration-100 ease-in-out border rounded shadow-sm focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50 disabled:opacity-50 disabled:cursor-not-allowed',
      classes: 'text-black placeholder-gray-400 bg-white border-gray-300 focus:border-blue-500 ',
      variants: {
        danger: 'border-red-300 bg-red-50 placeholder-red-200 text-red-900',
        success: 'border-green-300 bg-green-50 placeholder-gray-400 text-green-900',
        transparent: 'text-black border-transparent bg-transparent placeholder-gray-400'
      }
    }
  },
  't-input-group': {
    component: TInputGroup,
    props: {
      fixedClasses: {
        wrapper: '',
        label: 'block text-sm',
        body: '',
        feedback: 'text-xs',
        description: 'text-gray-400 text-xs'
      },
      classes: {
        wrapper: '',
        label: '',
        body: '',
        feedback: 'text-gray-400',
        description: 'text-gray-400'
      },
      variants: {
        danger: {
          label: 'text-red-500',
          feedback: 'text-red-500'
        },
        success: {
          label: 'text-green-500',
          feedback: 'text-green-500'
        }
      }
    }
  }
}

export default settings
