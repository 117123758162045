<template>
  <aside
    class="
      text-white
      bg-gray-900
      rounded-2xl
      h-full
    "
    :class="[
      style[mobilePosition],
      open
        ? 'duration-500 ease-in transition-all'
        : 'duration-700 ease-out hidden transition-all lg:w-24',
    ]"
  >
    <div>
      <sidenav-header />
      <sidenav-items />
    </div>
  </aside>
</template>

<script>
import SidenavItems from './Items.vue'
import SidenavHeader from './Header.vue'

export default {
  name: 'SettingsNavigation',
  components: { SidenavItems, SidenavHeader },
  props: {
    mobilePosition: {
      type: String,
      default: ''
    }
  },
  setup () {
    const open = true

    const style = {
      left: 'left-0 ',
      right: 'right-0 lg:left-0'
    }

    return { open, style }
  }
}
</script>

<style scoped>
.scrollbar::-webkit-scrollbar {
  width: 0;
  background: transparent; /* make scrollbar transparent */
}
.scrollbar {
  -ms-overflow-style: none; /* make scrollbar transparent on Firefox, IE and Edge*/
  scrollbar-width: none;
}
</style>
