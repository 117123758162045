var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-wrap items-start justify-center gap-10"},[_c('Content',{attrs:{"title":"Participants"}},[_c('div',{staticClass:"container"},[(_vm.template)?_c('Form',{ref:"form",attrs:{"fields":[
            { name: 'name', type: 'text' } ],"submitText":"Add Placeholder","onSubmit":this.addUser,"submitRight":true}}):_c('Form',{ref:"form",attrs:{"fields":[
            { name: 'firstName', type: 'text' },
            { name: 'lastName', type: 'text' },
            { name: 'email', type: 'email' } ],"submitText":"Add Recipient","onSubmit":this.addUser,"submitRight":true}})],1)]),(_vm.template)?_c('div',[_c('Content',{staticClass:"pb-10",attrs:{"title":"Reipient Placeholders"}},[_c('div',{staticClass:"mb-4"},[_c('t-toggle',{staticClass:"mr-3",attrs:{"value":true,"unchecked-value":false},model:{value:(_vm.sequential),callback:function ($$v) {_vm.sequential=$$v},expression:"sequential"}}),_c('span',{staticClass:"text-white"},[_vm._v("Sequential signing is "),_c('b',[_vm._v(_vm._s(_vm.sequential ? 'on' : 'off'))])])],1),_c('drop-list',{attrs:{"items":_vm.placeholders,"mode":"cut"},on:{"reorder":function($event){return $event.apply(_vm.placeholders)},"insert":_vm.addPlaceholder},scopedSlots:_vm._u([{key:"item",fn:function(ref){
          var item = ref.item;
return [_c('drag',{key:item.slug + ' item',staticClass:"mb-3",attrs:{"go-back":"","data":item},on:{"cut":function($event){return _vm.remove(_vm.placeholders, item)}}},[_c('DragAndDropCard',{attrs:{"item":item,"template":true},on:{"remove":function($event){return _vm.remove(_vm.placeholders, item)}}})],1)]}},{key:"feedback",fn:function(ref){
          var data = ref.data;
return [_c('div',{key:1,staticClass:"template-placeholder"},[_c('p',{staticClass:"w-full text-white text-center"},[_vm._v("Make "+_vm._s(data.name)+" a recipient")])])]}},{key:"empty",fn:function(ref){return [_c('div',{key:3,staticClass:"template-placeholder"},[_c('p',{staticClass:"w-full text-white text-center"},[_vm._v("Added recipients will appear here")])])]}}],null,false,536709910)})],1),_c('Content',{attrs:{"title":"Observers"}},[_c('drop-list',{attrs:{"items":_vm.templateObservers,"mode":"cut"},on:{"reorder":function($event){return $event.apply(_vm.templateObservers)},"insert":_vm.addTemplateObserver},scopedSlots:_vm._u([{key:"item",fn:function(ref){
          var item = ref.item;
return [_c('drag',{key:item.slug + ' item',staticClass:"mb-3",attrs:{"go-back":"","data":item},on:{"cut":function($event){return _vm.remove(_vm.templateObservers, item)}}},[_c('DragAndDropCard',{attrs:{"item":item,"template":""},on:{"remove":function($event){return _vm.remove(_vm.templateObservers, item)}}})],1)]}},{key:"feedback",fn:function(ref){
          var data = ref.data;
return [_c('div',{key:1,staticClass:"template-placeholder"},[_c('p',{staticClass:"w-full text-white text-center"},[_vm._v("Make "+_vm._s(data.name)+" an observer")])])]}},{key:"empty",fn:function(ref){return [_c('div',{key:3,staticClass:"template-placeholder"},[_c('p',{staticClass:"w-full text-white text-center"},[_vm._v("Drag observers here")])])]}}],null,false,2644494839)})],1),_c('t-button',{staticClass:"mt-8",attrs:{"enabled":!_vm.placeholders.length},on:{"click":_vm.onDone}},[_vm._v(" Continue ")])],1):_c('div',{staticClass:"flex flex-col"},[_c('Content',{staticClass:"pb-10",attrs:{"title":"Recipients"}},[_c('div',{staticClass:"mb-4"},[_c('t-toggle',{staticClass:"mr-3",attrs:{"value":true,"unchecked-value":false},model:{value:(_vm.sequential),callback:function ($$v) {_vm.sequential=$$v},expression:"sequential"}}),_c('span',{staticClass:"text-white"},[_vm._v("Sequential signing is "),_c('b',[_vm._v(_vm._s(_vm.sequential ? 'on' : 'off'))])])],1),_c('drop-list',{attrs:{"items":_vm.recipients,"mode":"cut"},on:{"reorder":function($event){return $event.apply(_vm.recipients)},"insert":_vm.addRecipient},scopedSlots:_vm._u([{key:"item",fn:function(ref){
          var item = ref.item;
return [_c('drag',{key:item.email + ' item',staticClass:"mb-3",attrs:{"go-back":"","data":item},on:{"cut":function($event){return _vm.remove(_vm.recipients, item)}}},[_c('DragAndDropCard',{attrs:{"item":item},on:{"remove":function($event){return _vm.remove(_vm.recipients, item)}}})],1)]}},{key:"feedback",fn:function(ref){
          var data = ref.data;
return [_c('div',{key:1,staticClass:"placeholder"},[_c('p',{staticClass:"w-full text-white text-center"},[_vm._v("Make "+_vm._s(data.name)+" a recipient")])])]}},{key:"empty",fn:function(ref){return [_c('div',{key:3,staticClass:"placeholder"},[_c('p',{staticClass:"w-full text-white text-center"},[_vm._v("Added recipients will appear here")])])]}}])})],1),_c('Content',{attrs:{"title":"Observers"}},[_c('drop-list',{attrs:{"items":_vm.observers,"mode":"cut"},on:{"reorder":function($event){return $event.apply(_vm.observers)},"insert":_vm.addObserver},scopedSlots:_vm._u([{key:"item",fn:function(ref){
          var item = ref.item;
return [_c('drag',{key:item.email + ' item',staticClass:"mb-3",attrs:{"go-back":"","data":item},on:{"cut":function($event){return _vm.remove(_vm.observers, item)}}},[_c('DragAndDropCard',{attrs:{"item":item},on:{"remove":function($event){return _vm.remove(_vm.observers, item)}}})],1)]}},{key:"feedback",fn:function(ref){
          var data = ref.data;
return [_c('div',{key:1,staticClass:"placeholder"},[_c('p',{staticClass:"w-full text-white text-center"},[_vm._v("Make "+_vm._s(data.name)+" an observer")])])]}},{key:"empty",fn:function(ref){return [_c('div',{key:3,staticClass:"placeholder"},[_c('p',{staticClass:"w-full text-white text-center"},[_vm._v("Drag observers here")])])]}}])})],1),_c('t-button',{staticClass:"mt-8",attrs:{"enabled":!_vm.recipients.length},on:{"click":_vm.onDone}},[_vm._v(" Continue ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }