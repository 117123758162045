import router from '@/router'
import store from '@/services/store'
import pinia from '@/stores'
import { useAuthStore } from 'pb-frontend-lib'
let authStore
const logout = function (reason: string): void {
  authStore = useAuthStore()

  console.log('Logout Event: Reason: ' + reason)
  try {
    authStore.loginTried = true

    fetch(process.env.VUE_APP_BACKEND_HOST + '/auth/logout', {
      method: 'post',
      credentials: 'include'
    })

    if (router.currentRoute.meta.requiresAuth) {
      router.push('/login')
    }
  } catch (error) {
    console.log('Error : Logout Event')
    console.log(error)
  }
}

export default { logout }
export { logout }
