import { SignStatus } from '@/types/PDF'

export function determinePDFStatus(pdf, userId): void {
  // Before, (see old, commented code from computed Vue values) - It was very possible for a PDF to be categorised into multiple sections...
  // I don't know if that was intentional, but I think this is a better solution

  //* If no JSON positions are found
  if (!pdf.positions) pdf.status = SignStatus.NeedPositions
  //* If PDF has already been completed
  else if (pdf.completed) pdf.status = SignStatus.Completed

  const signableBy: Array<string | number> = pdf.signableBy || []
  const signedBy: Array<string | number> = pdf.signedBy || []

  //* If there are more recipients than people who have signed
  if (signableBy.length > signedBy.length) {
    //* Check needed - One of the missing users who hasn't signed COULD include the current user
    if (signableBy.includes(userId) && !signedBy.includes(userId)) {
      pdf.status = SignStatus.ReadyToSign
    } else {
      pdf.status = SignStatus.WaitingForOthers
    }
  }
}

/* Disused function */
export function csvToIds(csv: string, delimiter = ','): Array<string | number> {
  const ids = []
  if (csv !== null) {
    csv.split(delimiter).map((value) => {
      try {
        ids.push(Number(value))
      } catch (error) {
        ids.push(value)
      }
    })
  }
  return ids
}
