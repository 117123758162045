import * as axios from 'axios'

// services/api.ts
import { useAuthStore } from 'pb-frontend-lib'
import pinia from '@/stores'

let authStore

const BASE_URL = process.env.VUE_APP_BACKEND_HOST
async function apiGetRefreshToken () {
  // Wrapped in "content" as this is what Nativescript does (keeping the frontend-lib as platform neutral code)
  const user = await upload({}, '/auth/refresh-token')
  return { content: user }
}

function upload (data, path, type?, headers?) {
  authStore = useAuthStore()
  const url = `${BASE_URL}${path}`
  return axios.default.post(url, data, {
    method: 'post',
    headers: {
      'Content-Type': type === 'multipart' ? 'multipart/form-data' : 'application/json',
      'Company-Name': authStore?.selectedCompany?.slug || '',
      ...headers
    },
    url: url,
    withCredentials: true
  }).then(response => {
    return response.data
  })
}

function get (path, headers?) {
  authStore = useAuthStore()

  const url = `${BASE_URL}${path}`
  return axios.default.get(url, {
    method: 'get',
    headers: {
      'Company-Name': authStore.selectedCompany ? authStore.selectedCompany.slug : '',
      ...headers
    },
    url: url,
    withCredentials: true
  })
}

function patch (data, path) {
  authStore = useAuthStore()

  const url = `${BASE_URL}${path}`
  return axios.default.patch(url, data, {
    method: 'patch',
    headers: {
      'Company-Name': authStore.selectedCompany.slug,
      'Content-Type': 'application/json'
    },
    url: url,
    withCredentials: true
  }).then(response => {
    console.log(response)
    return response.data
  })
}

function deletes (path, data = null) {
  authStore = useAuthStore()

  const url = `${BASE_URL}${path}`
  return axios.default.delete(url, {
    method: 'delete',
    headers: {
      'Company-Name': authStore.selectedCompany.slug
    },
    url: url,
    data: data,
    withCredentials: true
  }).then(response => {
    console.log(response)
    return response.data
  })
}

export { apiGetRefreshToken, upload, get, patch, deletes }
export default { apiGetRefreshToken, upload, get, patch, deletes }
