



















































































































































































import Vue from 'vue'
import Content from '../components/Content.vue'
import Form from '../components/Form.vue'
import DragAndDropCard from '../components/DragAndDropCard.vue'
import { Drag, DropList } from 'vue-easy-dnd'

export default Vue.extend({
  name: 'Recipients',
  data (): unknown {
    return {
      sequential: false,
      observers: [],
      recipients: [],
      placeholders: [],
      templateObservers: []
    }
  },
  computed: {
    template: function () {
      return this.$route.params.template
    }
  },
  components: {
    Content,
    Form,
    Drag,
    DropList,
    DragAndDropCard
  },
  methods: {
    addRecipient (event) {
      this.recipients.splice(event.index, 0, event.data)
    },
    addObserver (event) {
      this.observers.splice(event.index, 0, event.data)
    },
    addPlaceholder (event) {
      this.placeholders.splice(event.index, 0, event.data)
    },
    addTemplateObserver (event) {
      this.templateObservers.splice(event.index, 0, event.data)
    },
    addUser (values) {
      const data = JSON.parse(values)
      const obj = {
        ...data,
        id: this.recipients.length
      }
      if (!this.template && obj.firstName && obj.lastName && obj.email) {
        // ? Email duplication check
        const isObserver = this.observers.find(observer => observer.email === obj.email)
        const isRecipients = this.recipients.find(recipient => recipient.email === obj.email)

        if (isRecipients || isObserver) {
          this.$notify({
            group: 'main',
            title: 'Reused Email Address',
            text: 'Email has already been assigned to the ' + (isRecipients ? 'recipient' : 'observer') + ' called ' + obj.firstName + ' ' + obj.lastName,
            type: 'error'
          })
        } else {
          //* Success - Notify
          this.$notify({
            group: 'main',
            title: 'New Recipient',
            text: `${obj.firstName} ${obj.lastName} has been added as a recipient`,
            type: 'success'
          })
          //* Add recipient to array
          this.recipients.push(obj)
          this.$refs.form.clearValues()
        }
      } else if (this.template && obj.name) {
        const slug = obj.name.replace(/\W+/g, ' ')
          .split(/ |\B(?=[A-Z])/)
          .map(word => word.toLowerCase())
          .join('_')

        const nameExists = this.placeholders.find(placeholder => placeholder.slug === slug) || this.templateObservers.find(placeholderObserver => placeholderObserver.slug === slug)
        if (nameExists) {
          this.$notify({
            group: 'main',
            title: 'Reused Placeholder',
            text: `Name has already been assigned to the placeholder called ${obj.name}`,
            type: 'error'
          })
        } else {
          console.log(slug)
          obj.slug = slug
          this.placeholders.push(obj)
          this.$refs.form.clearValues()
        }
      } else {
        this.$notify({
          group: 'main',
          title: 'Recipient Field Empty',
          text: 'All fields must be filled',
          type: 'error'
        })
      }
    },
    onDone () {
      const id = this.$route.params.id
      const recipients = this.recipients
      const observers = this.observers
      const placeholders = this.placeholders
      const templateObservers = this.templateObservers
      const sequential = this.sequential

      if (this.template && this.placeholders.length <= 0) {
        this.$notify({
          group: 'main',
          title: 'No Placeholders',
          text: 'Templates must have at least ONE placeholder. Please add one, or drag-and-drop an observer',
          type: 'error'
        })
      } else if (this.template) {
        this.$router.push({ name: 'signaturePlacement', params: { id, placeholders, observers: templateObservers, sequential, template: true } })
      }

      if (!this.template && recipients.length <= 0) {
        this.$notify({
          group: 'main',
          title: 'No Recipients',
          text: 'Documents must have at least ONE recipient. Please add one, or drag-and-drop an observer',
          type: 'error'
        })
      } else if (!this.template) {
        this.$router.push({ name: 'signaturePlacement', params: { id, recipients, observers, sequential, template: false } })
      }
    },
    remove (array, value) {
      const index = array.indexOf(value)
      array.splice(index, 1)
    }
  }
})
