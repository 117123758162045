




















import Vue from 'vue'
import { useAuthStore } from 'pb-frontend-lib'
import VueTailwind from 'vue-tailwind'
import DashboardLayout from './components/dashboard/Layout.vue'
import FloatingLogoAnimation from './components/FloatingLogoAnimation.vue'
import serviceWorkerMixin from '@/mixins/update'
import router from '@/router/index'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { SweetAlertOptions } from 'sweetalert2'

//* See documentation here: https://fontawesome.com/v5.15/how-to-use/on-the-web/using-with/vuejs
library.add(faTimes)

export default Vue.extend({
  setup () {
    const authStore = useAuthStore()

    return { authStore }
  },
  components: {
    DashboardLayout,
    FloatingLogoAnimation
  },
  computed: {
    loginTried () {
      return this.authStore.loginTried
    }
  },
  async mounted () {
    await this.authStore.refreshToken(true)

    if (!this.authStore.selectedCompany) {
      // Just incase an old browser without local storage
      try {
        const lastSelectedCompany = JSON.parse(localStorage.getItem('lastSelectedCompany'))

        const ownedCompaniesIsArray = this.authStore.user?.ownedCompanies && Array.isArray(this.authStore.user?.ownedCompanies)
        const companiesIsArray = this.authStore.user?.companies && Array.isArray(this.authStore.user?.companies)

        if (ownedCompaniesIsArray && companiesIsArray && lastSelectedCompany?.id) {
          //* Check they still are apart of this company
          const ownsCompany = this.authStore.user.ownedCompanies.some(company => company.id === lastSelectedCompany.id)
          const partOfCompany = this.authStore.user.companies.some(company => company.id === lastSelectedCompany.id)

          if (ownsCompany || partOfCompany) {
            this.authStore.setCompany(lastSelectedCompany)
            console.log('Set Company From Local Backup')
          } else {
            //! No longer part of the company
            localStorage.removeItem('lastSelectedCompany')

            // Send to company selection with FULL alert (that is NOT ephemeral, as this message is important) to tell them they're not apart of the company anymore
            router.push('/company-selection')
            this.$swal.fire({
              icon: 'info',
              title: 'No longer apart of ' + (lastSelectedCompany?.name || 'that company'),
              text: 'You are no longer apart of the company you had selected. You will need to pick another company from the company selection page',
              confirmButtonText: 'Close',
              confirmButtonColor: '#ef4444'
            } as SweetAlertOptions)
          }
        }
      } catch (error) {
        console.warn('Likely an old browser, without Local Storage support')
        console.error(error)
      }
    }

    // Now we know a user is logged in, make a route change to desired location
    if (router.currentRoute.query.redirect) router.push(String(router.currentRoute.query.redirect))
  },
  mixins: [serviceWorkerMixin],
  watch: {
    updateExists: function (newValue, oldValue) {
      if (newValue) {
        this.$notify({
          group: 'main',
          title: 'An Update Is Ready',
          text: 'Please refresh to update',
          type: 'warning'
        })
      }
    },
    data () {
      return {
        expandOnHover: false,
        expandWithDelay: false,
        mobile: 'reduce',
        reduce: false
      }
    }
  }
})
