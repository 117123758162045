














































































































































































































































import { inject } from '@vue/composition-api'
import ThemeToggle from '../../ThemeToggle.vue'
import ProfilePicture from '../../ProfilePicture.vue'
// import store from '@/services/store'
import { SweetAlertOptions } from 'sweetalert2'
import 'animate.css'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faQuestionCircle, faCircle, faSearch, faEllipsisV } from '@fortawesome/free-solid-svg-icons'
import router from '@/router'
import { useAuthStore } from 'pb-frontend-lib'

//* See documentation here: https://fontawesome.com/v5.15/how-to-use/on-the-web/using-with/vuejs
library.add(faQuestionCircle, faCircle, faSearch, faEllipsisV)

export default {
  name: 'TopNavigation',
  data: function () {
    return {
      searchQuery: '',
      searchQueryOptions: 'signableBy',
      searchOptionsModalOpen: false,
      showSearchBar: !!this.$router.currentRoute.meta.showSearchBar
    }
  },
  watch: {
    $route (): void {
      this.showSearchBar = !!this.$router.currentRoute.meta.showSearchBar
    }
  },
  setup () {
    const toggle = inject('toggle')
    const authStore = useAuthStore()
    return { toggle, ThemeToggle, authStore }
  },
  computed: {
    hasTutorial: function () {
      return this.$route.meta.tutorial
    },
    user: function (): Record<symbol, string> {
      return this.authStore.user
    }
  },
  methods: {
    async search () {
      this.$router.push({ path: 'search', query: { query: this.searchQuery } })
    },
    async showTutorial (): Promise<void> {
      //! To add a tutorial to a page, use the META propery on the ROUTER

      if (this.$route.meta.tutorial) {
        // Await loop through each tutorial object
        for (const [index, tutorialProperties] of this.$route.meta.tutorial.entries()) {
          // Save to check for dismissals (end/closed)
          const tutorialPopupInstance = await this.$swal.fire({
            // Overwrites/defaults incase missing properties in the tutorialProperties object
            icon: 'question',
            title: (this.$route.name || '') + ' Tutorial ' + index,
            confirmButtonText: (this.$route.meta.tutorial.length - 1) === index ? 'Close' : 'Next',
            confirmButtonColor: (this.$route.meta.tutorial.length - 1) === index ? '#ef4444' : '#3b82f6',
            showClass: {
              popup: index === 0 ? 'animate__animated animate__fadeInTopRight animate__faster' : 'swal2-show'
            },
            hideClass: {
              popup: (this.$route.meta.tutorial.length - 1) === index ? 'animate__animated animate__fadeOutTopRight animate__faster' : 'swal2-hide'
            },
            // Overwrite defaults above with developer inputs
            ...tutorialProperties
          } as SweetAlertOptions)

          if (tutorialPopupInstance.isDismissed) {
            // Tutorial alert is "clicked away" or closed
            this.$notify({
              group: 'main',
              title: 'Tutorial Stopped',
              text: 'Click the tutorial button to start again.',
              type: 'info'
            })
            break
          }
        }
      }
    },
    logout (): void {
      fetch(process.env.VUE_APP_BACKEND_HOST + '/auth/logout', {
        method: 'post',
        credentials: 'include'
      })
      this.authStore.logout('User requested')
    }
  },
  components: {
    ThemeToggle,
    ProfilePicture
  }
}
