











import Vue from 'vue'
import PDFPreview from '@/components/PDFPreview.vue'
import TemplatePreview from '@/components/TemplatePreview.vue'

export default Vue.extend({
  components: {
    PDFPreview,
    TemplatePreview
  },
  props: {
    pdfs: Array,
    isTemplates: Boolean
  }

})
