




















import { library } from '@fortawesome/fontawesome-svg-core'
import { faQuestionCircle, faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons'
import { SweetAlertOptions } from 'sweetalert2'

//* See documentation here: https://fontawesome.com/v5.15/how-to-use/on-the-web/using-with/vuejs
library.add(faQuestionCircle)
library.add(faChevronDown)
library.add(faChevronUp)

export default {
  name: 'Content',
  methods: {
    showHelperAlert (): void {
      // Only shows if a body text is provided
      if (this.helperAlertTitle && this.helperAlertBody) {
        this.$swal.fire({
          icon: 'question',
          title: this.helperAlertTitle,
          text: this.helperAlertBody,
          confirmButtonText: 'Close',
          confirmButtonColor: '#ef4444'
        } as SweetAlertOptions)
      }
    }
  },
  components: {
  },
  props: {
    startClosed: {
      type: Boolean,
      default: false
    },
    quantity: {
      type: Number,
      default: null
    },
    collapsible: {
      type: Boolean,
      default: false
    },
    fullWidth: {
      type: Boolean,
      default: false
    },
    noCenter: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    titleTooltip: {
      type: String,
      default: ''
    },
    helperAlertTitle: {
      type: String,
      // Just a useful practice to take the title (in case the helper title is empty)
      default: function (): string {
        return this.title
      }
    },
    helperAlertBody: {
      type: String,
      default: ''
    },
    color: String,
    text: String,
    id: undefined
  },
  data: () => {
    return {
      hideContent: false
    }
  },
  mounted () {
    if (this.startClosed) {
      this.hideContent = true
    }
  }
}
