









import Vue from 'vue'
import Content from '../components/Content.vue'
import VueConfetti from 'vue-confetti'

Vue.use(VueConfetti)

export default Vue.extend({
  mounted () {
    this.$confetti.start()

    setTimeout(() => {
      this.$confetti.stop()
    }, 3000)
  },
  data: () => {
    return {
    }
  },
  methods: {

  },
  components: {
    Content
  }
})
