import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Home from '../views/Home.vue'
import Search from '../views/Search.vue'
import Login from '../views/Login.vue'
import ResetPassword from '../views/ResetPassword.vue'
import ChangePassword from '../views/ChangePassword.vue'
import Register from '../views/Register.vue'
import Settings from '../views/Settings.vue'
import Upload from '../views/Upload.vue'
import Recipients from '../views/Recipients.vue'
import Thanks from '../views/Thanks.vue'
import CompanySelection from '../views/CompanySelection.vue'

import SignaturePlacement from '../views/SignaturePlacement.vue'
import VueCompositionAPI from '@vue/composition-api'
import Appearance from '../views/SettingsPages/Appearance.vue'
import Page2 from '../views/SettingsPages/Page2.vue'
import Security from '../views/SettingsPages/Security.vue'
import Company from '../views/SettingsPages/Company.vue'
import Page4 from '../views/SettingsPages/Page4.vue'
import SignDocument from '../views/SignDocument.vue'
import ViewDocument from '../views/ViewDocument.vue'
import { SweetAlertOptions } from 'sweetalert2'
import { useAuthStore } from 'pb-frontend-lib'
import pinia from '@/stores'

Vue.use(VueCompositionAPI)
Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    meta: {
      showSearchBar: true,
      requiresAuth: true,
      tutorial: [
        {
          title: 'Welcome to Signatures by Prime Board',
          html: 'Signatures is an online platform for signing and distrubting PDF documents between two or more individual parties.<br><br>Click "Close" to learn more!<br>To skip this tutorial, just click away at any point',
          icon: 'success'
        },
        {
          title: 'Firstly!',
          html: 'This is your personalised dashboard which will list every PDF that: <br> - Is in need of your attention<br> - Is waiting for another\'s signature<br>Or<br> - Has been complete and is ready for viewing<br><br>It also lists any tempates you have uploaded',
          icon: 'info'
        },
        {
          title: 'To begin...',
          html: 'You may upload a document by navigating to the <a class="underline" href="/upload">upload page</a>',
          icon: 'success'
        }
        // Posible values
        // {
        //   position: 'top-end',
        //   grow: 'row',
        //   icon: 'success',
        //   title: 'Your work has been saved'
        // }
      ] as SweetAlertOptions[]
    },
    component: Home
  },
  {
    path: '/search',
    name: 'Search',
    meta: {
      requiresAuth: true,
      tutorial: [
        {
          title: 'Search',
          html: 'You can search for documents by email (owner email, recipient email, observer email, signer email)',
          icon: 'success'
        }
      ] as SweetAlertOptions[]
    },
    component: Search
  },
  {
    path: '/sign-document/:id',
    name: 'Sign Document',
    meta: { requiresAuth: true },
    component: SignDocument
  },
  {
    path: '/sign-document/:company/:id/:user/:token',
    name: 'Guest Sign Document',
    component: SignDocument,
    meta: {
      tutorial: [
        {
          title: 'Welcome to Signatures by Prime Board',
          html: 'Signatures is an online platform for signing and distrubting PDF documents between two or more individual parties.',
          icon: 'success'
        }
      ] as SweetAlertOptions[]
    }
  },
  {
    path: '/thanks',
    name: 'Thank You',
    component: Thanks,
    meta: {
      tutorial: [
        {
          title: 'Thank you for using Signatures by Prime Board',
          html: 'You can send your own documents by signing up for an account using the button on this page.',
          icon: 'success'
        }
      ] as SweetAlertOptions[]
    }
  },
  {
    path: '/view-document/:id',
    name: 'View Document',
    meta: {
      requiresAuth: true,
      tutorial: [
        {
          title: 'Saved Signatures',
          html: 'Here you can view your previously saved signatures. Signatures are saved when you submit one onto a document.',
          icon: 'info'
        }
      ] as SweetAlertOptions[]
    },
    component: ViewDocument
  },
  {
    path: '/view-document/:company/:id/:user/:token',
    name: 'Guest View Document',
    component: ViewDocument
  },
  {
    path: '/saved',
    name: 'SavedSignatures',
    meta: {
      requiresAuth: true,
      tutorial: [
        {
          title: 'Saved Signatures',
          html: 'Here you can view your previously saved signatures. Signatures are saved when you submit one onto a document.',
          icon: 'info'
        },
        {
          title: 'Safe and Secure',
          html: 'Your signatures are encrypted at rest and in transit.<br><br>Additionally, (should a malicious actor access your web browser/session), an additional password input will halt them from seeing your signatures!',
          icon: 'info'
        },
        {
          title: 'BE CAREFUL!',
          html: 'Giving your Signatures password to anyone will grant them access to your signatures!<br><br>Keep your password secure at all times!',
          icon: 'error'
        },
        {
          title: 'Staying Safe',
          html: 'Should you wish, you may delete your saved signatures on this page.',
          icon: 'success'
        }
      ] as SweetAlertOptions[]
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/SavedSignatures.vue')
  },
  {
    path: '/reset-password',
    name: 'reset-password',
    component: ResetPassword
  },
  {
    path: '/auth/change-password/:token',
    name: 'change-password',
    meta: { requiresAuth: false },
    component: ChangePassword
  },
  {
    path: '/auth/change-password',
    redirect: '/reset-password',
    meta: { requiresAuth: true }
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      tutorial: [
        {
          title: 'Welcome to Signatures by Prime Board',
          html: 'Signatures is an online platform for signing and distrubting PDF documents between two or more individual parties.<br><br>Click "Close" to learn more!<br>To skip this tutorial, just click away at any point',
          icon: 'success'
        },
        {
          title: 'Firstly!',
          html: 'To send a document, you\'ll need to sign-up for an account.<br><br>However, you can sign a document as a <b>guest</b>, without needing to create an account.',
          icon: 'info'
        }
      ] as SweetAlertOptions[]
    }
  },
  {
    path: '/upload',
    name: 'upload',
    meta: {
      requiresAuth: true,
      tutorial: [
        {
          title: 'Uploading a document?',
          html: 'Here you can upload a document <b>or</b> a template.<br><br>If you\'re looking to send a document to get a signature, you\'ll likely want to upload a "document" (on the left-side).'
        },
        {
          title: 'Templates',
          html: 'Templates can be used to upload a commonly used document that you\'d like to be signed in EXACTLY the same way. Use the right-side to upload a template document.',
          icon: 'info'
        }
      ] as SweetAlertOptions[]
    },
    component: Upload
  },
  {
    path: '/recipients/:id',
    name: 'recipients',
    meta: {
      requiresAuth: true,
      tutorial: [
        {
          title: 'Recipients?',
          html: 'Here you can add recipients to your document.<br>Recipients do not require an account to sign, therefore any active email address will suffice.',
          icon: 'question'
        },
        {
          title: 'Observers?',
          html: 'Observers will only be able to see a document once it has been signed by all of the recipients.<br><br>They will have access to download the signed document (including signatures) upon completion.',
          icon: 'question'
        },
        {
          title: 'Tip!',
          html: 'You can drag recipients onto the observer list (and visa-versa).',
          icon: 'success'
        },
        {
          title: 'Sequencial Signing',
          html: 'With sequencial signing activated, emails will be sent out to each recipient in sequence (starting from top to bottom).',
          icon: 'info'
        },
        {
          title: 'Please note',
          html: '<ul class="list-disc list-inside" role="list"> <li>Participants (recipients and observers) must have unique email addresses</li> <li>Each participant will recieve emails as the document progresses (when appropriate), so ensure the address is active, as:</li> <li>Participants cannot be edited after you press "Continue"</li></ul>',
          icon: 'info'
        }
      ] as SweetAlertOptions[]
    },
    component: Recipients
  },
  {
    path: '/register',
    name: 'register',
    component: Register,
    meta: {
      tutorial: [
        {
          title: 'Welcome to Signatures by Prime Board',
          html: 'Signatures is an online platform for signing and distrubting PDF documents between two or more individual parties.<br><br>Click "Close" to learn more!<br>To skip this tutorial, just click away at any point',
          icon: 'success'
        },
        {
          title: 'Firstly!',
          html: 'To send a document, you\'ll need to sign-up for an account.<br><br>However, you can sign a document as a <b>guest</b>, without needing to create an account.',
          icon: 'info'
        }
      ] as SweetAlertOptions[]
    }
  },
  {
    path: '/settings',
    component: Settings,
    meta: {
      requiresAuth: true,
      tutorial: [
        {
          title: 'Settings',
          html: 'Each settings page has it\'s own tutorial, to learn more about each configuration, go to the page and click the red (?) icon at the top right.',
          icon: 'question'
        }
      ] as SweetAlertOptions[]
    },
    redirect: 'settings/security',
    children: [
      {
        path: 'appearance',
        name: 'Appearance',
        component: Appearance
      },
      {
        path: 'page2',
        name: 'Page2',
        component: Page2
      },
      {
        path: 'security',
        name: 'Security',
        component: Security,
        meta: {
          tutorial: [
            {
              title: 'Security',
              html: 'This page allows you to change your security settings.',
              icon: 'question'
            },
            {
              title: 'BE CAREFUL!',
              html: 'Giving your Signatures password to anyone will grant them access to your documents (e.g. contracts, forms) along with your signatures!<br><br>Keep your password secure at all times!',
              icon: 'error'
            },
            {
              title: 'Stay Safe',
              html: 'You can add an additional layer of protection by "Enabling Two Factor Authentication".<br><br>This forces a user to require a secondary form of authentication (typically a mobile or desktop app) which generates a time-specific, randomising, ephemeral, password.',
              icon: 'success'
            },
            {
              title: 'Warning! (read carefully)',
              html: 'If TFA is enabled, you <b>WILL NOT BE ABLE</b> to sign into your account, should you lose your designated "Two Factor Authentication" device (e.g. mobile, desktop, laptop, dedicated hardware).<br><br>Be very careful with such device!',
              icon: 'error'
            }
          ] as SweetAlertOptions[]
        }
      },
      {
        path: 'company',
        name: 'Company',
        component: Company,
        meta: {
          tutorial: [
            {
              title: 'Company',
              html: 'On Signatures, your user account is Global. However, uploaded files belong in sets, that are called companies.<br><br>Therefore, to upload documents, you\'ll need to register (or select) a company, which you can do here.',
              icon: 'question'
            }
          ] as SweetAlertOptions[]
        }
      },
      {
        path: 'page4',
        name: 'Page4',
        component: Page4
      }
    ]
  },
  {
    path: '/signature-placement/:id',
    name: 'signaturePlacement',
    meta: {
      requiresAuth: true,
      tutorial: [
        {
          title: 'Signature Placements',
          html: 'Here you can lay out where you want each signature placement to go.<br><br>You can begin by dragging the coloured "Signature" box somewhere onto the document.',
          icon: 'question'
        },
        {
          title: 'Tip!',
          html: 'You can scroll down the page whilst dragging a placement box, or scroll down before, to place signatures onto different pages.',
          icon: 'success'
        },
        {
          title: 'Multiple Participants',
          html: 'Participants will only be able to sign their assigned signature placements. Use the coloured boxes to select which user will be assigned to the placement box you drag-and-drop next.',
          icon: 'question'
        },
        {
          title: 'Please note',
          html: '<ul class="list-disc list-inside" role="list"> <li>Recipients can make multiple signatures for each of their assigned placements</li> <li>You cannot change a signature\'s placement after clicking "Done"</li> <li>Signatures cannot overlap each other, or the edge of the page</li></ul>',
          icon: 'info'
        }
      ] as SweetAlertOptions[]
    },
    component: SignaturePlacement
  },
  {
    path: '/company-selection',
    name: 'CompanySelection',
    meta: { requiresAuth: true },
    component: CompanySelection
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const authStore = useAuthStore(pinia)
  const auth = !!authStore.user
  try {
    if (to.matched.some(record => record.meta.requiresAuth)) {
      if (!auth) {
        next({
          path: '/login',
          query: { redirect: to.fullPath }
        })
      } else {
        next()
      }
    } else {
      next()
    }
  } catch (error) {
    console.log('Error inslude router beforeEach')
    console.error(error)
  }
}
)

export default router
