


















































import { TInput, TCheckbox } from 'vue-tailwind/dist/components'

export default {
  name: 'Input',
  components: {
    TInput,
    TCheckbox
  },
  props: {
    label: String,
    name: String,
    maxlength: {
      type: Number,
      default: 255
    },
    type: {
      type: String,
      default: 'text'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    status: {
      type: String,
      default: ''
    },
    message: {
      type: String,
      default: ''
    },
    size: {
      type: Number,
      default: 6
    },
    confirm: {
      type: Boolean,
      default: false
    },
    hidden: {
      type: Boolean,
      default: false
    },
    default: {
      default: ''
    }
  },
  data: function () : Record<string, string | boolean> {
    return {
      value: this.default,
      confirmValue: this.default
    }
  },
  mounted: function () {
    this.update(this.value)
  },
  methods: {
    clearValue: function () {
      this.value = ''
      this.confirmValue = ''
      this.$emit('update-value', this.name, '')
    },
    update (value: string): void {
      this.$emit('update-value', this.name, value)
    }
  }
}
