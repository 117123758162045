






























import Vue from 'vue'
import Content from '../components/Content.vue'
import PDFViewer from '../components/PDFViewer.vue'
import { get } from '@/services/api'

export default Vue.extend({
  name: 'Home',
  components: {
    Content,
    PDFViewer
  },
  created: function () {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    var self = this
    this.expiryCounter = setInterval(function () {
      self.currentTime = Date.now()
    }, 1000)
  },

  computed: {
    s3LinkExpiryInSeconds: function () {
      try {
        const presignedUrl = new URL(this.pdf.preSignedUrl)
        const expiry = new Date(parseInt(presignedUrl.searchParams.get('Expires')) * 1000)
        const deltaSeconds = (new Date(expiry).getTime() - this.currentTime) / (1000)
        if (Math.round(deltaSeconds) > 0) return Math.round(deltaSeconds)
        else {
          clearTimeout(this.expiryCounter)
          return 0
        }
      } catch (error) {
        return 0
      }
    },
    isGuest: function () {
      return !!this.$route.params.token
    }
  },
  methods: {
    refresh: function () {
      location.reload()
    }
  },
  data () {
    return {
      pdf: null,
      requestFailed: false,
      scrollPosition: 0,
      currentTime: Date.now(),
      expiryCounter: undefined
    }
  },
  async mounted () {
    document.querySelector('main').addEventListener('scroll', (e) => {
      this.scrollPosition = (e.target as HTMLElement).scrollTop
    }, false)
    let url
    if (this.isGuest) {
      url = `/view/${this.$route.params.company}/${this.$route.params.id}/${this.$route.params.user}/${this.$route.params.token}/`
    } else {
      url = `/pdfs/${this.$route.params.id}`
    }

    await get(url).then(response => {
      this.pdf = response.data
    }).catch((e) => {
      this.requestFailed = true
      console.log(e)
    })

    console.log(this.pdf)
  },
  destroyed () : void {
    document.querySelector('main').removeEventListener('scroll', (e) => {
      this.scrollPosition = (e.target as HTMLElement).scrollTop
    }, false)
  }
})
