


































































import Vue from 'vue'
import SavedSignatures from './SavedSignatures.vue'
import TextBasedSignaturePad from './TextBasedSignaturePad.vue'
import { get } from '@/services/api'

export default Vue.extend({
  components: {
    SavedSignatures,
    TextBasedSignaturePad
  },
  props: {
    password: String
  },
  data: () => ({
    signatureType: 'drawing',
    signatureImage: null,
    options: {
      penColor: 'black'
    },
    allFieldsOption: true,
    displaySaved: false
  }),
  mounted () {
    if (!this.isGuest) {
      this.testPassword()
    }
  },
  computed: {
    isGuest: function () {
      return !!this.$route.params.token
    }
  },
  methods: {
    testPassword: function () {
      get('/signatures', { Authorization: this.password }).then(res => {
      //* Save returned data straight into the signatures
      }).catch(e => {
        //! Else catch and HTTP errors and emit a generic error message unless password is wrong.
        if (e.response.status === 401) {
          this.$notify({
            group: 'main',
            title: 'Saved Signatures',
            text: 'You submitted the wrong password.',
            type: 'error'
          })
        } else {
          this.$notify({
            group: 'main',
            title: 'Saved Signatures',
            text: 'There was an error retrieving your saved signatures',
            type: 'error'
          })
        }
      }
      )
    },
    save (data?) {
      if (this.signatureType === 'drawing') { // SignaturePad
        const { isEmpty, data } = this.$refs.signaturePad.saveSignature()
        if (!isEmpty) {
          console.log('signature data :>> ', data)
          this.$emit('recieve-signature', {
            option: this.allFieldsOption,
            data: data,
            saved: false
          })
          this.$emit('close-modal')
        } else {
          this.$notify({
            group: 'main',
            title: 'Invalid Signature',
            text: 'You need to enter a valid signature',
            type: 'warning'
          })
        }
        this.$refs.signaturePad.clearSignature()
      } else {
        // Save Text-based signature
        if (data.isEmpty) {
          this.$notify({
            group: 'main',
            title: 'Invalid Signature',
            text: 'Text Cannot Be Empty',
            type: 'warning'
          })
        } else {
          // Text not empty and valid
          this.$emit('recieve-signature', {
            option: this.allFieldsOption,
            data: this.signatureImage
          })
          this.$emit('close-modal')

          // this.textSignature
        }
      }
    },
    updateTextSignature (data) {
      this.signatureImage = data.data
    },
    saveStoredSignature (e) {
      this.$emit('recieve-signature', { option: this.allFieldsOption, data: e.signature, saved: true, id: e.id })
      this.$emit('close-modal')
    },
    undo () {
      this.$refs.signaturePad.clearSignature()
    }
  }
})
