







export default {
  name: 'PdfViewer',
  data (): unknown {
    return {
      thePDF: null,
      numPages: 0
    }
  },
  props: {
    page: Number,
    preview: Boolean,
    pdf: Object
  },
  mounted () : void {
    this.loadPdf(this.pdf)
  },
  methods: {
    async loadPdf (pdf) : Promise<void> {
      const page = await pdf.getPage(this.page)

      const scale = 1
      const viewport = page.getViewport(scale)

      const canvas = this.$refs.pdf
      const context = canvas.getContext('2d')
      canvas.height = viewport.height
      canvas.width = viewport.width

      const renderContext = {
        canvasContext: context,
        viewport: viewport
      }

      await page.render(renderContext)
      this.$emit('page-loaded', this.page)
    }
  }
}
