<template>
  <div
    class="
      bg-gray-900
      flex
      items-center
      justify-center
      mb-6
      pb-6
      pt-3
      sticky
      top-0
      z-10
    "
  >
    <img
      src="/pb-logo.jpeg"
      width="50"
      height="50"
      class="rounded-md"
    />
  </div>
</template>

<script>
export default {
  name: 'SidenavHeader'
}
</script>
