






















import Vue from 'vue'
import Form from '../components/Form.vue'
import Content from '../components/Content.vue'
// import store from '@/services/store'
import router from '@/router'
import { upload } from '@/services/api'
import { useAuthStore } from 'pb-frontend-lib'

export default Vue.extend({
  name: 'Register',
  data: () => {
    return {
      providedEmail: null,
      loaded: false
    }
  },
  setup () {
    const authStore = useAuthStore()

    return { authStore }
  },
  components: {
    Form,
    Content
  },
  mounted () {
    const { email } = router.currentRoute.query
    if (email) {
      this.providedEmail = email
    }
    this.loaded = true
  },
  methods: {
    onSubmit (values) {
      fetch(`${process.env.VUE_APP_BACKEND_HOST}/auth/register`, { method: 'post', credentials: 'include', body: values, headers: { 'Content-Type': 'application/json' } }).then((response) => {
        if (response.status === 201) {
          response.json().then((json) => {
            this.authStore.setUser(json.data.user)

            const { token, company } = router.currentRoute.query
            if (company && token) {
              upload({ email: json.data.user.email, companySlug: company, token: token }, '/companies/join-with-token').then(res => {
                console.log(res)
              }).catch((e) => {
                const res = JSON.parse(e.request.response)
                console.log(e)
                this.$notify({
                  group: 'main',
                  title: 'Add User',
                  text: res.message,
                  type: 'error'
                })
              })
            }

            router.push(this.$route.query.redirect || '/')
          })
        }
      }
      )
    }
  }
})
