























































import { deletes, patch } from '@/services/api'
import Modal from './Modal.vue'
import Form from './Form.vue'
import ConfirmModal from './ConfirmModal.vue'
import IconWithTooltip from './IconWithTooltip.vue'
import Vue from 'vue'

export default Vue.extend({
  name: 'GroupListItem',
  components: {
    Modal,
    Form,
    ConfirmModal,
    IconWithTooltip
  },
  props: {
    group: {
      type: Object
    },
    showActions: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      editModalOpen: false,
      deleteModalOpen: false
    }
  },
  methods: {
    deleteGroup: function (data) {
      deletes(`/groups/${this.group.id}`).then(() => {
        this.deleteModalOpen = false
        this.$notify({
          group: 'main',
          title: 'Delete Group',
          text: `You successfully deleted the '${this.group.name}' group!`,
          type: 'success'
        })
        this.$emit('groupSaved')
      })
    },
    saveGroup: function (data) {
      console.log(this.data)
      patch(data, `/groups/${this.group.id}`).then(() => {
        this.editModalOpen = false
        this.$notify({
          group: 'main',
          title: 'Edit Group',
          text: `You successfully edited the '${this.group.name}' group!`,
          type: 'success'
        })
        this.$emit('groupSaved')
      }).catch(() => {
        this.$notify({
          group: 'main',
          title: 'Edit Group',
          text: 'Could not edit group.',
          type: 'error'
        })
      })
    }
  }
})
