<template>
  <div v-if="pdf" :class="showFull ? 'w-full' : null">
    <Spinner v-show="loading"/>
    <div v-show="showFull && !loading" class="flex justify-center items-center flex-col gap-5 w-full">
        <!-- <t-button @click="togglePreview">Minimize</t-button> -->
        <PDFViewer :url="pdf.preSignedUrl" @finished-loading="onPDFLoad"/>
        <template v-for="(input, index) in retrievedSignatures">
          <Signable
            :key="index"
            :id="index"
            :img="completeSignables[index] ? completeSignables[index].image : null"
            :page="input.position.page"
            :colour="colours[0]"
            :x="input.position.x"
            :y="input.position.y"
            @clicked="(signable) => $emit('clicked', signable)" />
        </template>
        <template v-for="(input, index) in otherUserInputs">
          <Signable
            :key="'other' + index"
            :id="index"
            :page="input.position.page"
            colour="bg-gray-400 crossed"
            :x="input.position.x"
            :y="input.position.y"
            noText
            disabled
             />
        </template>
    </div>
    <div class="container" v-show="!showFull && !loading">
      <PDFViewer :url="pdf.preSignedUrl" :preview="true"/>
    <div @click="togglePreview" class="overlay">Expand</div>
  </div>
  </div>
</template>

<script>
import Vue from 'vue'
import Spinner from '../components/Spinner.vue'
import PDFViewer from '../components/PDFViewer.vue'
import Signable from '../components/Signable.vue'
import { useAuthStore } from 'pb-frontend-lib'

export default Vue.extend({
  name: 'DocumentDisplay',
  setup () {
    const authStore = useAuthStore()

    return { authStore }
  },
  data () {
    return {
      numPages: undefined,
      src: undefined,
      showFull: true,
      loading: false,
      retrievedSignatures: [],
      otherUserInputs: [],
      colours: ['bg-blue-200', 'bg-red-200', 'bg-yellow-200', 'bg-green-200', 'bg-indigo-200']
    }
  },
  components: {
    Spinner,
    PDFViewer,
    Signable
  },
  props: {
    completeSignables: Array,
    pdf: {
      type: Object
    }
  },
  methods: {
    togglePreview () {
      this.showFull = !this.showFull
    },
    onPDFLoad () {
      const data = JSON.parse(this.pdf.positions)
      const otherInputs = []
      const retrievedSignatures = []
      data.users.map(user => {
        // "isGuest user checker"
        const currentUser = this.$route.params.token ? this.pdf.currentViewer : this.authStore.user.id

        if (user.id === currentUser) {
          retrievedSignatures.push(...user.inputs)
        } else {
          if (user.type === 'signer' && user.inputs) otherInputs.push(...user.inputs)
        }
      })
      this.retrievedSignatures = retrievedSignatures
      this.otherUserInputs = otherInputs
      this.$emit('num-of-signatures', this.retrievedSignatures)
    },
    wait (ms) {
      var start = new Date().getTime()
      var end = start
      while (end < start + ms) {
        end = new Date().getTime()
      }
    }
  }
})
</script>

<style scoped>
.pdf{
  width:800px;
  max-width:95vw;
}
.container {
  position: relative;
}
.overlay {
  position: absolute;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5); /* Black see-through */
  color: #f1f1f1;
  width: 100%;
  height: 100%;
  padding-top:50%;
  transition: .5s ease;
  opacity:0;
  color: white;
  font-size: 40px;
  text-align: center;
  border-radius:15px;
}
.container:hover .overlay {
  opacity: 1;
  cursor:pointer;
  }
</style>
