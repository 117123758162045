






















































export default {
  data () {
    return {
      textSignature: '',
      canvas: null,
      context: null,
      signatureFonts: [
        'Sacramento',
        'Mrs Saint Delafield',
        'Mr De Haviland',
        'Homemade Apple',
        'Dawning of a New Day'
      ],
      fontSize: 100,
      signatureImageURL: null,
      font: ''
    }
  },
  mounted () {
    this.canvas = this.$refs.canvas
    this.context = this.canvas.getContext('2d')
    this.context.textAlign = 'center'
    this.font = 'Sacramento'
    this.context.font = `${this.fontSize}px ${this.font}`
  },
  watch: {
    font (newVal, oldVal) {
      this.font = newVal
      this.renderSignatureInCanvas()
    }
  },
  methods: {
    setFont (event) {
      const selectedFont = event.target.value

      this.font = selectedFont
      this.renderSignatureInCanvas()
    },
    fitTextOnCanvas () {
      // console.log('this.context.measureText(this.textSignature) :>> ', this.context.measureText(this.textSignature).width)
      // console.log('this.canvas.width :>> ', this.canvas.width)

      const canvasTextWidth = this.context.measureText(this.textSignature).width
      const canavsWidthLimit = this.canvas.width
      const canvasMinimumWidth = canavsWidthLimit - 100

      if (canvasTextWidth > canavsWidthLimit) {
        this.increaseFontSize()
      }

      if (canvasTextWidth < canvasMinimumWidth) {
        this.decreaseFontSize(canvasMinimumWidth)
      }
    },
    increaseFontSize () {
      do {
        this.fontSize--
        this.context.font = this.fontSize + 'px ' + this.font
      } while (this.context.measureText(this.textSignature).width > this.canvas.width)
    },
    decreaseFontSize (canvasMinimumWidth) {
      do {
        if (this.fontSize >= 100) {
          break
        }
        this.fontSize++
        this.context.font = this.fontSize + 'px ' + this.font
      } while (this.context.measureText(this.textSignature).width < canvasMinimumWidth)
    },
    renderSignatureInCanvas () {
      this.clearCanvas()
      // this.context.font = `${this.fontSize}px ${this.font}`

      const isEmpty = this.textSignature.trim().length === 0

      if (isEmpty) {
        return
      }
      this.fitTextOnCanvas()
      this.context.font = `${this.fontSize}px ${this.font}`

      this.context.fillText(this.textSignature, (this.canvas.width / 2), (this.canvas.height / 2) + 20)

      this.signatureImageURL = this.canvas.toDataURL()

      this.$emit('update-text-signature', {
        data: this.signatureImageURL,
        isEmpty: isEmpty
      })
    },
    clearCanvas () {
      this.context.clearRect(0, 0, this.canvas.width, this.canvas.height)
    }

  },
  computed: {

  }
}
