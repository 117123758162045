




































import Vue from 'vue'
import SignatureForm from '../components/SignatureForm.vue'
import Content from '../components/Content.vue'
import DocumentDisplay from '../components/DocumentDisplay.vue'
import Modal from '../components/Modal.vue'
import InfoDisplay from '../components/InfoDisplay.vue'
import { upload, get } from '@/services/api'
import Form from '../components/Form.vue'
import Spinner from '@/components/Spinner.vue'

export default Vue.extend({
  name: 'Home',
  components: {
    SignatureForm,
    Content,
    DocumentDisplay,
    Modal,
    InfoDisplay,
    Form,
    Spinner
  },
  data () {
    return {
      disabledDoneButtonOverwrite: true,
      pdf: null,
      progress: '0%',
      waitModal: false,
      modalOpen: false,
      passwordModalOpen: false,
      completeSignables: [],
      retrievedPositions: [],
      inputs: [],
      currentSignable: null,
      numOfSignatures: 0,
      password: null,
      requestFailed: false
    }
  },
  computed: {
    disableDoneButton: function () {
      return this.disabledDoneButtonOverwrite || (this.completeSignables.length !== this.numOfSignatures)
    },
    isGuest: function () {
      return !!this.$route.params.token
    }
  },
  mounted () {
    let url
    if (this.isGuest) {
      url = `/view/${this.$route.params.company}/${this.$route.params.id}/${this.$route.params.user}/${this.$route.params.token}/`
    } else if (this.$route.params.template) {
      url = `/templates/${this.$route.params.id}`
    } else {
      url = `/pdfs/${this.$route.params.id}`
    }

    get(url).then(response => {
      this.pdf = response.data
      this.disabledDoneButtonOverwrite = false
    }).catch((e) => {
      this.requestFailed = true
      console.log(e)
    })
  },
  methods: {
    async recieveSignature (signature) {
      // From the USER - not recieving them from the database/server
      // Recieve signature(s) from the USER - not recieving them from the database/server
      const completeSignables = this.completeSignables
      const retrievedPositions = this.retrievedPositions

      //! Friendly reminder that the "option"s property on the signature means USE FOR ALL FIELDS "option" ¯\_ (ツ)_/¯
      if (signature.option === true) {
        //* If user used the "use for all fields", clear the current inputs array
        let signatureObject

        // If this signature is not already saved, and from a registered user (proceed to save the sig image)
        if (!signature.saved && !this.isGuest) {
          try {
            signatureObject = await upload(JSON.stringify({ signature: signature.data, password: this.password }), '/signatures')
          } catch (error) {
            this.$notify({
              group: 'main',
              title: 'Could not save signature',
              text: 'There was an error saving the signature',
              type: 'error'
            })
          }
          // Unsure why we're uploading, then instantly getting the signature record...
          try {
            signatureObject = (await get(`/signatures/${signatureObject.id}`, { Authorization: this.password })).data
          } catch (error) {
            this.$notify({
              group: 'main',
              title: 'Could not get signature',
              text: 'There was an error retrieving the saved signature',
              type: 'error'
            })
          }
        }

        // For each signature placeholder (which has just been filled using the "Use for all fields" "option")
        for (const index in retrievedPositions) {
          completeSignables[index] = retrievedPositions[index]

          // If this signature was JUST saved (from code above)
          if (!signature.saved && !this.isGuest) {
            completeSignables[index].signatureId = signatureObject.id
            completeSignables[index].image = signatureObject.signature
          } else {
            completeSignables[index].signatureId = signature.id
            completeSignables[index].image = signature.data
          }
          // I assume this is here for removing Vue reactivity
          const input = JSON.parse(JSON.stringify(completeSignables[index]))

          if (!this.isGuest) {
            delete input.image
          }
          Vue.set(this.inputs, index, input)
        }
      } else {
        // Else just a single signature
        completeSignables[this.currentSignable.id] = retrievedPositions[this.currentSignable.id]
        let signatureObject
        if (!signature.saved && !this.isGuest) {
          try {
            signatureObject = await upload(JSON.stringify({ signature: signature.data, password: this.password }), '/signatures', { Authorization: this.password })
          } catch (error) {
            this.$notify({
              group: 'main',
              title: 'Could not save signature',
              text: 'There was an error saving the signature',
              type: 'error'
            })
          }
          try {
            signatureObject = (await get(`/signatures/${signatureObject.id}`, { Authorization: this.password })).data
          } catch (error) {
            this.$notify({
              group: 'main',
              title: 'Could not get signature',
              text: 'There was an error retrieving the saved signature',
              type: 'error'
            })
          }
          completeSignables[this.currentSignable.id].signatureId = signatureObject.id
          completeSignables[this.currentSignable.id].image = signatureObject.signature
          const input = JSON.parse(JSON.stringify(completeSignables[this.currentSignable.id]))
          if (!this.isGuest) {
            delete input.image
          }
          Vue.set(this.inputs, this.currentSignable.id, input)
        } else {
          completeSignables[this.currentSignable.id].signatureId = signature.id
          completeSignables[this.currentSignable.id].image = signature.data
          const input = JSON.parse(JSON.stringify(completeSignables[this.currentSignable.id]))
          if (!this.isGuest) {
            delete input.image
          }
          Vue.set(this.inputs, this.currentSignable.id, input)
        }
      }
      this.completeSignables = [...completeSignables]

      const definedSignables = this.completeSignables.filter(signable => !!signable)
      this.progress = (definedSignables.length / this.numOfSignatures * 100).toFixed() + '%'
    },
    recieveTextSignature () {
      return null
    },
    signableClicked (signable) {
      if (this.password || this.isGuest) {
        this.currentSignable = signable
        this.modalOpen = true
      } else {
        this.passwordModalOpen = true
        this.currentSignable = signable
      }
    },
    passwordSubmit (data) {
      const password = JSON.parse(data).password
      this.password = password
      this.passwordModalOpen = false
      this.modalOpen = true
    },
    openPasswordModal () {
      this.modalOpen = false
      this.passwordModalOpen = true
    },
    saveSignatures () {
      if (this.completeSignables.length === this.numOfSignatures) {
        // Show the modal and disable the button (stops represses)
        this.disabledDoneButtonOverwrite = true
        this.waitModal = true

        let url
        let headerOverwrites = {}

        if (this.isGuest) {
          url = `/sign/${this.$route.params.company}/${this.$route.params.id}/${this.$route.params.user}/${this.$route.params.token}/`
          headerOverwrites = { 'Company-Name': `pbsign_${this.$route.params.company}` }
        } else {
          url = `/pdfs/sign/${this.$route.params.id}`
        }

        const body = { inputs: this.inputs, password: null }
        if (!this.isGuest) {
          body.password = this.password
        }

        upload(body, url, '', headerOverwrites)
          .then(() => {
            if (this.isGuest) this.$router.push('/thanks')
            else this.$router.push('/')
            this.disabledDoneButtonOverwrite = false
            this.waitModal = false
          }).catch((e) => {
            if (e.message === 'Request failed with status code 403') {
              this.$notify({
                group: 'main',
                title: 'Signatures Already Posted',
                text: 'Signatures have already been posted to this PDF',
                type: 'error'
              })
            } else {
              this.$notify({
                group: 'main',
                title: 'Error Posting Signature',
                text: 'Please try again later',
                type: 'error'
              })
            }
          })
      } else {
        this.$notify({
          group: 'main',
          title: 'Missing Signature',
          text: 'Please sign all available signatures',
          type: 'error'
        })
      }
    },
    updateSignatures (retrievedPositons) {
      this.retrievedPositions = retrievedPositons
      this.numOfSignatures = this.retrievedPositions.length
    }
  }
})
