<template>
  <div
    class="
      bg-gray-900
      flex
      items-center
      justify-center
      sticky
      z-10
      p-52
    "
  >
    <img
      src="/pb-logo.jpeg"
      width="100"
      height="100"
      class="rounded-lg animate-bounce"
    />
  </div>
</template>

<script>
export default {
  name: 'FloatingLogoAnimation'
}
</script>
