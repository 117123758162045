
























import Vue from 'vue'
import { get, deletes } from '@/services/api'

export default Vue.extend({
  props: {
    allowDelete: Boolean,
    password: String
  },
  computed: {
    emptySignatures: function () {
      return !this.signatures.length
    }
  },
  data () {
    return {
      signatures: []
    }
  },
  methods: {
    deleteSignature (id) {
      deletes('/signatures/' + id)
        .then(() => {
          // Remember: ID isn't the index in the array (but the ID from the DB)
          this.signatures = this.signatures.filter(value => {
            return value.id !== id
          })
        })
        .catch(err => {
          console.log('Tried to delete signature')
          console.error(err)
          this.$notify({
            group: 'main',
            title: 'Error Deleting Signature',
            text: 'There was an error deleting the saved signature',
            type: 'error'
          })
        })
    }
    // postSignatures () {
    //   patch({ positions: JSON.stringify(this.signatures) }, '/pdfs/' + this.$route.params.id).then(
    //     this.$router.push('/')
    //   )
    // }
  },
  mounted () {
    get('/signatures', { Authorization: this.password }).then(res => {
      //* Save returned data straight into the signatures
      console.log(res)

      this.signatures = res.data
    }).catch(e => {
      //! Else catch and HTTP errors and emit a generic error message unless password is wrong.
      if (e.response.status === 401) {
        this.$notify({
          group: 'main',
          title: 'Saved Signatures',
          text: 'You submitted the wrong password.',
          type: 'error'
        })
        this.$emit('invalidPassword')
      } else {
        this.$notify({
          group: 'main',
          title: 'Saved Signatures',
          text: 'There was an error retrieving your saved signatures',
          type: 'error'
        })
      }
    }
    )
  }
})
