

















import Vue from 'vue'
import IconWithTooltip from './IconWithTooltip.vue'
import UserGroupsModal from './UserGroupsModal.vue'
import { deletes } from '@/services/api'
import { useAuthStore } from 'pb-frontend-lib'
export default Vue.extend({
  setup () {
    const authStore = useAuthStore()
    return {
      authStore
    }
  },
  name: 'UserListItem',
  components: {
    IconWithTooltip,
    UserGroupsModal
  },
  props: {
    user: {
      type: Object
    }
  },
  methods: {
    removeUser: function () {
      const c = confirm(`Are you sure you want to remove ${this.user.firstName} ${this.user.lastName} from this company?`)
      if (c) {
        deletes('/companies/remove-user', { id: this.user.id, companyId: this.authStore.selectedCompany.id }).then(res => {
          this.getUsers()
        }).catch((e) => {
          const res = JSON.parse(e.request.response)
          console.log(e)
          this.$notify({
            group: 'main',
            title: 'Remove User',
            text: res.message,
            type: 'error'
          })
        })
      }
    }
  }
})
