








export default {
  name: 'ThemeToggle',
  setup () {
    const darkTheme = true
    return { darkTheme }
  },
  methods: {
    theme (): void {
      if (this.darkTheme) {
        this.darkTheme = false
        document.body.classList.add('light')
      } else {
        this.darkTheme = true
        document.body.classList.remove('light')
      }
    }
  }
}
