
































import Vue from 'vue'
import Modal from '../../components/Modal.vue'
import Form from '../../components/Form.vue'
import Content from '../../components/Content.vue'
import TwoFactorAuthentication from '../../components/TwoFactorAuthentication.vue'
import { useAuthStore } from 'pb-frontend-lib'

export default Vue.extend({
  name: 'Security',
  setup () {
    const authStore = useAuthStore()

    return { authStore }
  },
  components: {
    Modal,
    Content,
    TwoFactorAuthentication,
    Form
  },
  data () {
    return {
      modalOpen: false,
      passwordResetAlert: false,
      passwordResetAlertType: 'default',
      passwordResetAlertText: ''
    }
  },
  computed: {
    isTfaEnabled: function () {
      return this.authStore.user.isTfaEnabled
    }
  },
  methods: {
    resetPassword (fields) {
      console.log(fields)
      fetch(process.env.VUE_APP_BACKEND_HOST + '/auth/change-password-logged-in', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: fields,
        credentials: 'include'
      })
        .then(response => {
          if (response.status === 201) {
            this.passwordResetAlertType = 'success'
            this.passwordResetAlertText = 'Password Changed.'
            this.passwordResetAlert = true
          } else if (response.status === 401) {
            this.passwordResetAlertType = 'danger'
            this.passwordResetAlertText = 'Incorrect Password'
            this.passwordResetAlert = true
          } else {
            console.log(this)
            this.passwordResetAlertType = 'danger'
            this.passwordResetAlertText = 'Password Not Changed.'
            this.passwordResetAlert = true
          }
        })
    }
  }
})
