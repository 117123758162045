
















import interact from 'interactjs'

export default {
  name: 'Placeholder',
  props: {
    id: Number,
    signatureColour: String
  },
  data: function () {
    return ({
      restrictedArea: null,
      resizeOption: {
        edges: { left: true, right: true, bottom: true, top: true }
      },
      dragOption: {
        modifiers: [
          interact.modifiers.restrictRect({
            restriction: '#restrictedArea',
            endOnly: true
          })
        ]
      },
      //* These are for if the user drags the placeholder box outside the PDF bounds (it'll return to the last known position)
      lastSuccessfulDragX: 0,
      lastSuccessfulDragY: 0,
      // values for interact.js transformation
      x: 0,
      y: 0,
      w: 100,
      h: 50,
      hasMoved: false,
      savedColour: '',
      uniqueId: Math.floor(Math.random() * 999999999)
    })
  },

  computed: {
    style () {
      return {
        height: `${this.h}px`,
        width: `${this.w}px`,
        transform: `translate(${this.x}px, ${this.y}px)`
      }
    }
  },

  methods: {
    highlight (delay: number): void{
      setTimeout(() => {
        this.$refs.interact.$el.classList.add('highlighted-placeholder')
      }, delay)
      setTimeout(() => {
        this.$refs.interact.$el.classList.remove('highlighted-placeholder')
      }, delay * 2)
    },
    resizemove (event): void {
      this.w = event.rect.width
      this.h = event.rect.height

      this.x += event.deltaRect.left
      this.y += event.deltaRect.top
    },
    dragstart (event): void {
      //* Inform parent of change (used to show and hide toolbar when on mobile)
      this.$emit('drag-start', event)
    },
    dragmove (event): void {
      this.x += event.dx
      this.y += event.dy
    },
    dragend (event): void {
      //* Inform parent of change (used to show and hide toolbar when on mobile)
      this.$emit('drag-end', event)

      const placeholder = event.target
      const relatedPdf = event.relatedTarget

      //* If the placeholder contains the "can-drop" class (set in func "initDropZone"), set to last successful drop or home position (0,0) - hardcoded
      if (!placeholder.classList.contains('can-drop')) {
        //* Just set the x and y to the last known coords? Done.
        this.x = this.lastSuccessfulDragX
        this.y = this.lastSuccessfulDragY

        //* Alert user they made a mistake and that the signature is back at the last drop or was never created (in case this was the first drop of placeholder)
        this.$notify({
          group: 'main',
          title: 'Error Placing Signature',
          text: 'Signatures must be placed on the PDF. ' + (this.hasMoved ? 'The signature has been moved back.' : 'The signature has been deleted.'),
          type: 'error'
        })
      } else {
        const dropzonePosition = placeholder.getBoundingClientRect()
        const elementPosition = relatedPdf.getBoundingClientRect()
        const pageNumber = parseInt(relatedPdf.id)
        const x = elementPosition.left - dropzonePosition.left
        const y = elementPosition.top - dropzonePosition.top

        this.$emit('save-coordinates', { id: this.id - 1, page: pageNumber, x: x, y: y, placeholder: this })

        //* I think these put the placeholder into the PDF page itself to ensure it doesn't scroll with the toolbar (which is locked)
        placeholder.parentNode.removeChild(placeholder)
        relatedPdf.prepend(placeholder)
        placeholder.classList.add('absolute')

        if (this.hasMoved === false) {
          this.$emit('new-signature', this)
          this.x = -x
          this.y = -y
          this.savedColour = this.signatureColour
        }

        //* Has been moved from home location (inside the toolbar)
        this.hasMoved = true

        //* The drag was successful, set these coords as known "can-drop" area that
        //* makes sense for user to revert back to, incase the next drag isn't successful
        this.lastSuccessfulDragX = -x
        this.lastSuccessfulDragY = -y
      }
      placeholder.classList.remove('can-drop')
    },
    initDropZone (): void{
      //* This only allows the placeholders to be added to the PDF area (called .dropzone)
      interact('.dropzone').dropzone({
        //* 100% of the placeholder must be inside the dropzone
        overlap: 1,
        accept: '.placeholder',
        ondropactivate: function (event) {
          const item = event.relatedTarget
          item.classList.add('dragging')
        },
        ondropdeactivate: function (event) {
          const item = event.relatedTarget
          item.classList.remove('dragging', 'cannot-drop')
        },
        ondragenter: function (event) {
          const item = event.relatedTarget
          item.classList.remove('cannot-drop')
          item.classList.add('can-drop')
        },
        ondragleave: function (event) {
          const item = event.relatedTarget
          item.classList.remove('can-drop')
          item.classList.add('cannot-drop')
        }
      })

      //* And this blocks any 0.00001 overlap with other .placeholder (s) - The .add and .remove classes are swapped from the above functions in options object
      interact('.placeholder').dropzone({
        overlap: 0.00000001,
        ondragenter: function (event) {
          const item = event.relatedTarget
          item.classList.add('cannot-drop')
          item.classList.remove('can-drop')
        },
        ondragleave: function (event) {
          const item = event.relatedTarget
          item.classList.add('can-drop')
          item.classList.remove('cannot-drop')
        }
      })
    }
  },
  mounted () : void {
    this.initDropZone()
  }
}
