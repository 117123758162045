










































































import { get, upload, deletes, patch } from '@/services/api'
import Modal from '../../components/Modal.vue'
import Form from '../../components/Form.vue'
import GroupListItem from '../../components/GroupListItem.vue'
import UserListItem from '../../components/UserListItem.vue'
import Vue from 'vue'
import { useAuthStore } from 'pb-frontend-lib'

export default Vue.extend({
  name: 'Company',
  components: {
    Modal,
    Form,
    GroupListItem,
    UserListItem
  },
  setup () {
    const authStore = useAuthStore()

    return { authStore }
  },
  data () {
    return {
      email: '',
      userSearchTerm: '',
      groupModalOpen: false,
      usersViewable: false,
      groupsViewable: false,
      users: [],
      queriedUsers: [],
      groups: []
    }
  },
  mounted: function () {
    if (this.authStore.selectedCompany) {
      this.getUsers()
      this.getGroups()
    }
  },
  watch: {
    userSearchTerm: function (newTerm) {
      if (newTerm.length) {
        this.getUsers(true)
      } else {
        this.getUsers()
      }
    }
  },
  methods: {
    getUsers (query = false) {
      if (query) {
        const url = '/company-users?' +
          `or=firstName||$startsL||${this.userSearchTerm}` +
          `&or=lastName||$startsL||${this.userSearchTerm}`

        get(url).then(res => {
          if (res.status === 200) {
            this.usersViewable = true
            this.users = res.data
          }
        })
      } else {
        get('/company-users').then(res => {
          if (res.status === 200) {
            this.usersViewable = true
            this.users = res.data
          }
        })
      }
    },
    getGroups () {
      get('/groups').then(res => {
        if (res.status === 200) {
          this.groups = res.data
          this.groupsViewable = true
        }
      })
    },
    removeUser: function (id) {
      const user = this.users.find((user) => user.id === id)
      const c = confirm(`Are you sure you want to remove ${user.firstName} ${user.lastName} from this company?`)
      if (c) {
        deletes('/companies/remove-user', { id: id, companyId: this.authStore.selectedCompany.id }).then(res => {
          this.email = ''
          this.getUsers()
        }).catch((e) => {
          const res = JSON.parse(e.request.response)
          console.log(e)
          this.$notify({
            group: 'main',
            title: 'Remove User',
            text: res.message,
            type: 'error'
          })
        })
      }
    },
    addUser: function () {
      if (this.email.length > 4) {
        upload({ email: this.email, companyId: this.authStore.selectedCompany.id }, '/companies/add-user').then(res => {
          this.email = ''
          if (res.message) {
            this.$notify({
              group: 'main',
              title: 'Add User',
              text: res.message,
              type: 'success'
            })
          }
          this.getUsers()
        }).catch((e) => {
          const res = JSON.parse(e.request.response)
          console.log(e)
          this.$notify({
            group: 'main',
            title: 'Add User',
            text: res.message,
            type: 'error'
          })
        })
      }
    },
    addGroup: function (data) {
      upload(data, '/groups').then(res => {
        this.getGroups()
      })
    },
    editGroup: function (data) {
      const json = JSON.parse(data)
      patch(data, `/groups/${json.id}`).then(res => {
        this.getGroups()
      })
    }
  }
})
