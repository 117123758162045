







import Vue from 'vue'
export default Vue.extend({
  props: {
    page: Number,
    x: Number,
    y: Number,
    id: Number,
    img: String,
    colour: String,
    noText: Boolean,
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      style: {
        left: 0,
        top: 0
      }
    }
  },
  mounted () {
    const pdfViewer = document.getElementById('PDFViewer')
    const signable = this.$refs.signable
    signable.parentNode.removeChild(signable)
    const targetPDF = pdfViewer.childNodes[this.page - 1] as HTMLElement
    targetPDF.classList.add('relative')
    targetPDF.append(signable)
    this.style.left = -this.x + 'px'
    this.style.top = -this.y + 'px'
  },
  methods: {
    clicked () {
      this.$emit('clicked', { id: this.id, x: this.x, y: this.y })
    }
  }
})
