














import Vue from 'vue'
import Form from './Form.vue'
import { useAuthStore } from 'pb-frontend-lib'

export default Vue.extend({
  setup () {
    const authStore = useAuthStore()

    return { authStore }
  },
  components: {
    Form
  },
  name: 'SubmitTwoFactorCode',
  props: {
    mode: String
  },
  data () {
    return {
      isSubmitting: false,
      showSendCode: false,
      sendCodeSelection: '',
      codeSent: false,
      codeError: false,
      modes: {
        enable: { buttonText: 'Enable 2FA', buttonColour: '', url: '/2fa/enable', message: '2FA Enabled' },
        disable: { buttonText: 'Disable 2FA', buttonColour: 'error', url: '/2fa/disable' },
        authenticate: { buttonText: 'Authenticate', buttonColour: '', url: '/2fa/authenticate' }
      }
    }
  },
  computed: {
    isTfaEnabled: function () {
      return this.authStore.user.isTfaEnabled
    }
  },
  methods: {
    authenticate (fields) {
      if (!this.isSubmitting) {
        fetch(process.env.VUE_APP_BACKEND_HOST + this.modes[this.mode].url, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: `{"tfaCode": "${JSON.parse(fields).authenticationCode}"}`,
          credentials: 'include'
        })
          .then(response => {
            this.isSubmitting = false
            if (response.status === 200) {
              this.$emit('toggle-modal')
              if (this.mode === 'authenticate') {
                this.$emit('authenticated')
              }
              this.authStore.refreshToken()
              console.log(this.modes[this.label].message)
            } else {
              // trigger alert in parent
            }
          })
      }
    },

    sendCode () {
      if (this.state.sendCodeSelection !== '') {
        fetch(`${process.env.VUE_APP_BACKEND_HOST}/2fa/send-${this.state.sendCodeSelection}`, {
          method: 'POST',
          credentials: 'include'
        })
          .then((response) => {
            if (response.status === 201) {
              this.codeSent = true
              this.codeError = false
            } else {
              this.codeSent = false
              this.codeError = true
            }
          })
      }
    }
  }
})
