<template>
  <ul class="lg:w-52 sm:w-full flex flex-wrap">
    <!-- <sidenav-item title="Appearance" to="/settings/appearance">
      <home-icon />
    </sidenav-item> -->
<!--
    <sidenav-item title="Status" to="/admin/status">
      <status-icon />
    </sidenav-item> -->

    <!-- <sidenav-item title="Notifications" to="/settings/page2">
      <archives-icon />
    </sidenav-item> -->

    <sidenav-item title="Security" to="/settings/security">
      <upload-icon />
    </sidenav-item>

    <sidenav-item title="Company" to="/settings/company">
      <span class="h-6 w-6 inline-block flex justify-center items-center">
        <font-awesome-icon :icon="['fas', 'building']" />
      </span>
    </sidenav-item>
  </ul>
</template>

<script>
import SidenavItem from './Item.vue'

/* ICONS */
// import HomeIcon from './icons/Home.vue'
// import SettingsIcon from './icons/Settings.vue'
// import ArchivesIcon from './icons/Archives.vue'
import UploadIcon from './icons/Upload.vue'

export default {
  name: 'SidenavItems',
  components: {
    // HomeIcon,
    SidenavItem,
    // SettingsIcon,
    // ArchivesIcon,
    UploadIcon
  }
}
</script>
