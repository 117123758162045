var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',{staticClass:"hover:bg-gray-100"},[_c('td',{staticClass:"pr-0 pl-2 py-2"},[_vm._v(" "+_vm._s(_vm.group.name)+" "),(_vm.group.description && _vm.group.description.length)?_c('IconWithTooltip',{staticClass:"mx-1",attrs:{"icon":"info-circle"}},[_c('p',[_vm._v(_vm._s(_vm.group.description))])]):_vm._e()],1),(_vm.showActions)?_c('td',[_c('div',{staticClass:"flex items-center justify-center gap-2"},[_c('IconWithTooltip',{attrs:{"icon":"pen"},on:{"click":function($event){_vm.editModalOpen = true}}},[_c('p',{staticClass:"text-sm whitespace-nowrap"},[_vm._v("Edit this group")])]),_c('IconWithTooltip',{staticClass:"text-red-700",attrs:{"icon":"trash"},on:{"click":function($event){return _vm.$refs.confirmDeleteModal.open()}}},[_c('p',{staticClass:"text-sm whitespace-nowrap"},[_vm._v("Delete this group")])])],1),_c('Modal',{attrs:{"open":_vm.editModalOpen},on:{"close-modal":function($event){_vm.editModalOpen = !_vm.editModalOpen}}},[_c('Form',{attrs:{"fields":[
          {name: 'name', type: 'text', default: _vm.group.name},
          {name: 'description', type: 'text', default: _vm.group.description},
          {label: 'Create', name: 'canCreate', type: 'checkbox', default: _vm.group.canCreate},
          {label: 'Read (View all documents)', name: 'canRead', type: 'checkbox', default: _vm.group.canRead},
          {label: 'Update', name: 'canUpdate', type: 'checkbox', default: _vm.group.canUpdate},
          {label: 'Delete', name: 'canDelete', type: 'checkbox', default: _vm.group.canDelete},
          {label: 'Superuser (Manage groups)', name: 'isSuperuser', type: 'checkbox', default: _vm.group.isSuperuser} ],"width":"400px","submitText":"Save","onSubmit":_vm.saveGroup,"submitRight":true}})],1),_c('ConfirmModal',{ref:"confirmDeleteModal",attrs:{"open":_vm.deleteModalOpen,"buttonVariant":"error","confirmText":"Delete"},on:{"confirm":_vm.deleteGroup}},[_vm._v(" Are you sure you want to delete the '"+_vm._s(_vm.group.name)+"' group? "),_c('br'),_c('br'),_vm._v(" This may affect your permissions and you may no longer be able to do certain tasks! ")])],1):_vm._e(),_c('td',{staticClass:"text-center"},[_c('label',{staticClass:"checkbox"},[_c('input',{attrs:{"type":"checkbox","readonly":"","disabled":""},domProps:{"checked":_vm.group.canCreate}})])]),_c('td',{staticClass:"text-center"},[_c('label',{staticClass:"checkbox"},[_c('input',{attrs:{"type":"checkbox","readonly":"","disabled":""},domProps:{"checked":_vm.group.canRead}})])]),_c('td',{staticClass:"text-center"},[_c('label',{staticClass:"checkbox"},[_c('input',{attrs:{"type":"checkbox","readonly":"","disabled":""},domProps:{"checked":_vm.group.canUpdate}})])]),_c('td',{staticClass:"text-center"},[_c('label',{staticClass:"checkbox"},[_c('input',{attrs:{"type":"checkbox","readonly":"","disabled":""},domProps:{"checked":_vm.group.canDelete}})])]),_c('td',{staticClass:"text-center"},[_c('label',{staticClass:"checkbox"},[_c('input',{attrs:{"type":"checkbox","readonly":"","disabled":""},domProps:{"checked":_vm.group.isSuperuser}})])])])}
var staticRenderFns = []

export { render, staticRenderFns }