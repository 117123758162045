






















































































import { get, upload } from '@/services/api'
import Vue from 'vue'
import Modal from './Modal.vue'
import IconWithTooltip from './IconWithTooltip.vue'

export default Vue.extend({
  name: 'UserGroupsModal',
  data: function () {
    return {
      selectedGroup: null,
      modalOpen: false,
      groups: [],
      otherGroups: []
    }
  },
  props: {
    user: Object
  },
  components: {
    Modal,
    IconWithTooltip
  },
  watch: {
    modalOpen: function (newState) {
      if (newState) {
        this.getGroups()
      }
    }
  },
  computed: {
    selectGroups: function () {
      return this.otherGroups.map((group) => {
        return { value: group.id, label: group.name }
      })
    }
  },
  methods: {
    addUser: function (groupId) {
      upload('{}', `/groups/${groupId}/add-user/${this.user.id}`).then((res) => {
        console.log(res)
        this.getGroups()
      })
    },
    removeUser: function (groupId) {
      upload('{}', `/groups/${groupId}/remove-user/${this.user.id}`).then((res) => {
        this.getGroups()
      })
    },
    getGroups: function () {
      get(`/company-users/${this.user.id}?join=groups`).then((res) => {
        if (res.status === 200) {
          this.groups = res.data.groups
          get('/groups').then((res) => {
            if (res.status === 200) {
              const ids = this.groups.map(group => group.id)
              console.log(ids)
              const groups = res.data.filter((group) => {
                if (ids.includes(group.id)) {
                  console.log(group.name, 'false')
                  return false
                }
                console.log(group.name, 'true')
                return true
              })
              this.otherGroups = groups
            }
          })
        }
      })
    },
    close: function () {
      this.modalOpen = false
      this.$emit('close')
    },
    open: function () {
      this.modalOpen = true
    }
  }
})
