




























// import store from '@/services/store'
import { upload } from '@/services/api'
import { useAuthStore } from 'pb-frontend-lib'

const STATUS_INITIAL = 0; const STATUS_SAVING = 1; const STATUS_SUCCESS = 2; const STATUS_FAILED = 3

export default {
  name: 'app',
  props: {
    template: {
      type: Boolean,
      default: false
    },
    accept: {
      type: String,
      // See: https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/file#unique_file_type_specifiers
      default: 'application/pdf'
    }
  },
  setup () {
    const authStore = useAuthStore()

    return { authStore }
  },
  data () : unknown {
    return {
      uploadedFiles: [],
      uploadError: null,
      currentStatus: null,
      uploadFieldName: 'pdf',
      uploadedID: null
    }
  },
  computed: {
    isInitial () : boolean {
      return this.currentStatus === STATUS_INITIAL
    },
    isSaving () : boolean {
      return this.currentStatus === STATUS_SAVING
    },
    isSuccess () : boolean {
      return this.currentStatus === STATUS_SUCCESS
    },
    isFailed () : boolean {
      return this.currentStatus === STATUS_FAILED
    }
  },
  methods: {
    nextClicked (id) {
      this.$router.push({ name: 'recipients', params: { id: id, template: this.template } })
    },
    reset () : void {
      this.currentStatus = STATUS_INITIAL
      this.uploadedFiles = []
      this.uploadError = null
    },
    save (formData) : void{
      this.currentStatus = STATUS_SAVING

      const url = this.template ? '/templates' : '/pdfs'

      upload(formData, url, 'multipart')
        .then(x => {
          this.uploadedFiles = [].concat(x)
          this.uploadedID = x.id
          this.currentStatus = STATUS_SUCCESS
        })
        .catch(err => {
          this.uploadError = err.response
          this.currentStatus = STATUS_FAILED
          this.$notify({
            group: 'main',
            title: 'PDF Upload Error',
            text: 'There was an error uploading the PDF',
            type: 'error'
          })
        })
    },
    filesChange (fieldName, fileList) : void {
      const formData = new FormData()

      if (!fileList.length) {
        this.$notify({
          group: 'main',
          title: 'Error Opening File',
          text: 'Please select a PDF document to upload',
          type: 'error'
        })
        return
      }

      if (!String(fileList[0].name).endsWith('.pdf')) {
        this.$notify({
          group: 'main',
          title: 'Mismatching File Type',
          text: 'We can only accept PDF documents',
          type: 'error'
        })
        return
      }

      //! These are the hard coded values that ensure each pdf_document appears on the owners front page, despite them not needing to sign
      formData.append('file', fileList[0])
      formData.append('owner', `${this.authStore.user.id}`)

      // save it
      this.save(formData)
    }
  },
  mounted () : void{
    this.reset()
  }
}

