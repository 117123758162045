




import md5 from 'md5'
import { useAuthStore } from 'pb-frontend-lib'

export default {
  name: 'ProfilePicture',
  setup () {
    const authStore = useAuthStore()

    return { authStore }
  },
  data: function () {
    return {
      profilePictureURL: '../assets/profile_picture_placeholder.png'
    }
  },
  mounted () : void {
    const email = this.authStore.user.email
    if (email) {
      //* Implementation from here: https://en.gravatar.com/site/implement
      const hash = md5(this.authStore.user.email.trim().toLowerCase())
      this.profilePictureURL = 'https://www.gravatar.com/avatar/' + hash
    } // Continue using the hardcoded placeholder
  }
}
