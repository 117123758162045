






















import Modal from './Modal.vue'
import Vue from 'vue'

export default Vue.extend({
  name: 'ConfirmModal',
  components: {
    Modal
  },
  props: {
    buttonVariant: {
      type: String,
      default: ''
    },
    confirmText: {
      type: String,
      default: 'Confirm'
    }
  },
  data: function () {
    return {
      modalOpen: false
    }
  },
  methods: {
    confirm: function () {
      this.modalOpen = false
      this.$emit('confirm')
    },
    close: function () {
      this.modalOpen = false
      this.$emit('close')
    },
    open: function () {
      this.modalOpen = true
    }
  }
})
