<template>
  <div
    class="
      bg-gray-900
      flex
      items-center
      justify-center
    "
  >
  </div>
</template>

<script>
export default {
  name: 'SidenavHeader'
}
</script>
