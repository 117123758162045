



















import Vue from 'vue'
import Content from '../components/Content.vue'
import UploadArea from '../components/UploadArea.vue'
import { useAuthStore } from 'pb-frontend-lib'

export default Vue.extend({
  components: {
    Content,
    UploadArea
  },
  setup () {
    const authStore = useAuthStore()

    return { authStore }
  },
  computed: {
    hasSelectedCompany: function (): boolean {
      return !!this.authStore.selectedCompany
    },
    hasCompany: function (): boolean {
      return !(this.authStore.user.companies.length === 0)
    }
  }
})
