
























































































import Vue from 'vue'
import Placeholder from '../components/Placeholder.vue'
import Content from '../components/Content.vue'
import UserSelector from '../components/UserSelector.vue'
import Modal from '../components/Modal.vue'

export default Vue.extend({
  components: {
    Placeholder,
    Content,
    Modal,
    UserSelector
  },
  props: {
    currentPage: Number,
    numOfSignatures: Number,
    recipientsProp: {
      type: Array
    },
    signaturePlaceholderComponents: {
      type: Array
    }
  },
  data () {
    return {
      offsetToolbar: false,
      modalOpen: false,
      temp: [],
      scrollPosition: 0,
      selected: '',
      recipients: [],
      currentSelection: '',
      signatureColour: 'bg-blue-300',
      colours: ['bg-blue-300', 'bg-red-300', 'bg-yellow-200', 'bg-green-300', 'bg-indigo-300'],
      hideToolbarOverwrite: false
    }
  },
  computed: {
    hideToolbar () {
      return this.mobile && this.hideToolbarOverwrite
    },
    mobile () {
      return screen.width < 768
    }
  },
  methods: {
    toggleToolbarOffset () {
      console.log((this.offsetToolbar ? 'Showing' : 'Hiding') + ' toolbar')
      this.offsetToolbar = !this.offsetToolbar
    },
    scrollTo (placeholderToScroll) {
      this.modalOpen = false
      const indexOfPlaceholder = this.signaturePlaceholderComponents.findIndex(placeholder => placeholder.uniqueId === placeholderToScroll.uniqueId)
      document.getElementsByTagName('main')[0].scrollTo({
        top: this.signaturePlaceholderComponents[indexOfPlaceholder].$el.offsetTop,
        left: 0,
        behavior: 'smooth'
      })
      this.signaturePlaceholderComponents[indexOfPlaceholder].highlight(500)
    },
    dragStart (event): void {
      //* When a placeholder has been dragged or dropped
      //* Show or hide the toolbar (when on mobile)
      this.hideToolbarOverwrite = true
    },
    dragEnd (event): void {
      //* When a placeholder has been dragged or dropped
      //* Show or hide the toolbar (when on mobile)
      this.hideToolbarOverwrite = false
    },
    newSignature (newPlaceholderComponent) {
      this.$emit('new-signature', newPlaceholderComponent)
    },
    passCoordinates (c) {
      this.$emit('save-coordinates', { currentRecipient: this.currentSelection, coords: c })
    },
    updateCurrentSelection (name: string) {
      this.currentSelection = name
    },
    postSignatures () {
      if (this.currentSelection) {
        this.$emit('post-signatures', this.currentSelection)
      }
    },
    nextPage () {
      this.$emit('next-page')
    },
    previousPage () {
      this.$emit('previous-page')
    }
  },
  mounted () {
    let recipients
    if (this.$route.params.template) {
      recipients = this.$route.params.placeholders
    } else {
      recipients = this.$route.params.recipients
    }

    //* Adding an id into the array
    recipients.forEach((element, index, arr) => { arr[index].id = index })

    this.recipients = recipients
    this.currentSelection = this.recipients[0]

    document.querySelector('main').addEventListener('scroll', (e) => {
      this.scrollPosition = (e.target as HTMLElement).scrollTop
    }, false)
  },
  destroyed () {
    document.querySelector('main').removeEventListener('scroll', (e) => {
      this.scrollPosition = (e.target as HTMLElement).scrollTop
    }, false)
  }
})
