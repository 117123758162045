









import Vue from 'vue'
export default Vue.extend({
  name: 'IconWithTooltip',
  props: {
    icon: {
      type: String,
      default: ''
    }
  }
})
