





















export default {
  name: 'TemplatePreview',
  props: {
    pdf: Object
  },
  data () {
    return {
      showInfo: false
    }
  },
  computed: {
    pdfParticipants: function () {
      return JSON.parse(this.pdf.positions).users.map(u => `${u.name} (${u.slug})`).join(', ')
    },
    pdfDatasourcePretty: function () { return JSON.stringify(this.pdf.positions, null, 2) },
    pdfTime: function (): string {
      const date = new Date(this.pdf.dateUploaded)
      return date.toLocaleTimeString('en-gb', { hour: '2-digit', minute: '2-digit' })
    },
    pdfDate: function (): string {
      const date = new Date(this.pdf.dateUploaded)
      return date.toLocaleDateString('en-gb')
    }
  }
}
