import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
// import store from './services/store'
// import { useAuthStore } from 'pb-frontend-lib'
import './assets/tailwind.css'
import './assets/main.css'

//* Third party JS/TS libraries
import VueInteractJs from 'vue-interactjs'
import Notifications from 'vue-notification'
import VueSignature from 'vue-signature-pad'
import VueTailwind from 'vue-tailwind'
import tailwindSettings from './tailwind-settings'
import VTooltip from 'v-tooltip'
import VueSweetalert2 from 'vue-sweetalert2'

//* Third party CSS
import 'sweetalert2/dist/sweetalert2.min.css'

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faEllipsisV, faTrash, faBuilding, faPen, faInfoCircle, faUsersCog, faUserPlus, faPlus, faChevronCircleLeft, faChevronCircleRight } from '@fortawesome/free-solid-svg-icons'

import { createPinia, PiniaVuePlugin } from 'pinia'
import pinia from '@/stores/index'
Vue.use(PiniaVuePlugin)
// const pinia = createPinia()
//* See documentation here: https://fontawesome.com/v5.15/how-to-use/on-the-web/using-with/vuejs
//* Search and grab the name of the icon you'd like to use, import it (on line 13:10 - should have syntax highlighting) then use the following:
library.add(faEllipsisV, faTrash, faBuilding, faPen, faInfoCircle, faUsersCog, faUserPlus, faPlus, faChevronCircleLeft, faChevronCircleRight)

//* Thirdy party Vue components
Vue.component('font-awesome-icon', FontAwesomeIcon)

//* Thirdy party Vue plugins
Vue.use(VueTailwind, tailwindSettings)
Vue.use(VueSweetalert2)
Vue.use(VTooltip)
Vue.use(VueSignature)
Vue.config.productionTip = false
Vue.use(Notifications)
// @ts-ignore - Using library correctly, but still has type issues (see: https://github.com/yoroshikudozo/vue-interactjs)
Vue.use(VueInteractJs)

new Vue({
  router,
  // store,
  pinia,
  render: h => h(App)
}).$mount('#app')
