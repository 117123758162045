










//* Old classes inside "isSelected"
// border-l-8 border-white pr-2

import Vue from 'vue'
export default Vue.extend({
  props: {
    recipient: Object,
    selected: Object,
    colours: Array
  },
  data () {
    return {
    }
  },
  methods: {
    clicked () {
      this.$emit('current-selection', this.recipient)
      this.$emit('signature-colour', this.colours[this.recipient.id % this.colours.length])
    }
  },
  computed: {
    isSelected: function () {
      return (this.recipient.id === this.selected.id)
    },
    ringColour: function () {
      //* Break colour string into array of string
      const colourBreakdown = this.colours[this.recipient.id % this.colours.length].split('-')
      colourBreakdown[0] = 'ring'
      //* Set this to 200 if you want the border to be lighter than the background (set to 300)
      colourBreakdown[2] = parseInt(colourBreakdown[2]) + 100
      return colourBreakdown.join('-')
    }
  }
})
