





















import Vue from 'vue'
import Content from '../components/Content.vue'
import Form from '../components/Form.vue'

export default Vue.extend({
  name: 'ChangePassword',
  data () {
    return {
      token: this.$route.params.token,
      show2FA: false,
      alert: false,
      alertType: 'success',
      alertText: ''
    }
  },
  components: {
    Content,
    Form
  },
  methods: {
    onSubmit (values) {
      fetch(process.env.VUE_APP_BACKEND_HOST + '/auth/change-password', {
        method: 'POST',
        credentials: 'include',
        body: JSON.stringify({ ...JSON.parse(values), t: this.token }),
        headers: { 'Content-Type': 'application/json' }
      }).then((response) => {
        if (response.status === 201) {
          this.alertText = 'Your password has been changed, you can now log in as normal.'
          this.alertType = 'success'
          this.alert = true
        } else if (response.status === 401) {
          this.alertText = 'Invalid Link.'
          this.alertType = 'danger'
          this.alert = true
        } else {
          this.alertText = 'Unknown Error'
          this.alertType = 'danger'
          this.alert = true
        }
      }
      )
    }
  }
})
