<template>
  <div>
    <slot/>
  </div>
</template>

<script>
import { provide, ref, readonly, watch, inject } from '@vue/composition-api'

export default {
  name: 'DashboardProvider',
  watch: {
    $route (to, from) {
      this.closeOnRouteChange()
    }
  },
  setup (props, context) {
    const open = ref(false)

    const toggle = () => {
      open.value = !open.value
    }

    // close side navigation when you click on a sidenav item.
    // set the html tag overflow to hidden.
    const closeOnRouteChange = () => {
      if (open.value === true) {
        open.value = false
        document.documentElement.style.overflow = 'hidden'
      }
    }

    provide('open', readonly(open))
    provide('toggle', toggle)
    return { closeOnRouteChange }
  }
}
</script>
