
























import Vue from 'vue'
import SubmitTwoFactorCode from './SubmitTwoFactorCode.vue'
import { useAuthStore } from 'pb-frontend-lib'

export default Vue.extend({
  name: 'TwoFactorAuthentication',
  setup () {
    const authStore = useAuthStore()

    return { authStore }
  },
  components: {
    SubmitTwoFactorCode
  },
  created (): void {
    this.getQrCode()
  },
  data () {
    return {
      showKey: false,
      secret: null,
      qrCode: null,
      enableAlert: false,
      enableAlertText: null
    }
  },
  computed: {
    isTfaEnabled: function () {
      return this.authStore.user.isTfaEnabled
    }
  },
  methods: {
    getQrCode () {
      if (this.qrCode === null && !this.isTfaEnabled) {
        fetch(process.env.VUE_APP_BACKEND_HOST + '/2fa/generate', {
          method: 'POST',
          credentials: 'include'
        })
          .then(response => {
            if (response.status === 201) {
              response.json().then(result => {
                this.secret = result.secret
                this.qrCode = result.qrCode
              })
            } else {
              const alert = 'Could not get QR Code'

              this.enableAlert = true
              this.alert = alert

              this.$notify({
                group: 'main',
                title: alert,
                text: 'Please close this menu and try again later',
                type: 'error'
              })
            }
          })
      }
    }
  }
})
