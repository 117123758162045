

















































export default {
  name: 'ContentBox',
  components: {
  },
  props: {
    pdf: {
      type: Object
    },
    progress: {
      type: String,
      default: '0%'
    },
    disabledDoneButton: {
      type: Boolean,
      default: false
    }
  },
  data () : unknown {
    return {
      offsetToolbar: false,
      scrollPosition: 0
    }
  },
  methods: {
    saveSignatures () : void {
      this.$emit('save-signatures')
    },
    toggleToolbarOffset () {
      console.log((this.offsetToolbar ? 'Showing' : 'Hiding') + ' toolbar')
      this.offsetToolbar = !this.offsetToolbar
    }
  },
  computed: {
    mobile (): boolean {
      return screen.width < 768
    },
    pdfTime: function (): string {
      const date = new Date(this.pdf.dateUploaded)
      return date.toLocaleTimeString('en-gb', { hour: '2-digit', minute: '2-digit' })
    },
    pdfDate: function (): string {
      const date = new Date(this.pdf.dateUploaded)
      return date.toLocaleDateString('en-gb')
    }
  },
  mounted () : void {
    document.querySelector('main').addEventListener('scroll', (e) => {
      this.scrollPosition = (e.target as HTMLElement).scrollTop
    }, false)
  },
  destroyed () : void {
    document.querySelector('main').removeEventListener('scroll', (e) => {
      this.scrollPosition = (e.target as HTMLElement).scrollTop
    }, false)
  }
}
