






























import Input from './Input.vue'

export default {
  name: 'Form',
  components: {
    Input
  },
  props: {
    fields: {
      type: Array
    },
    width: {
      type: String,
      default: null
    },
    submitText: {
      type: String,
      default: 'Submit'
    },
    noBackground: {
      type: Boolean,
      default: false
    },
    submitRight: {
      type: Boolean,
      default: false
    },
    alert: {
      type: Boolean,
      default: () : boolean => false
    },
    alertType: {
      type: String,
      default: () : string => ''
    },
    alertText: {
      type: String,
      default: () : string => ''
    },
    onSubmit: Function
  },
  created (): void {
    this.fields.forEach(field => {
      if (!field.label) {
        field.label = this.camelCaseToWords(field.name)
      }
    })
  },
  data (): Record<string, any> {
    return {
      fieldValues: {}
    }
  },
  computed: {
    computedFields (): Record<string, unknown>[] {
      const newFields = []
      this.fields.forEach(field => {
        if (!field.label) {
          field.label = this.camelCaseToWords(field.name)
        }
        newFields.push(field)
      })
      return newFields
    }
  },
  methods: {
    updateValue (field: string, value: string|boolean|number) : void {
      if (this.fieldValues[field] !== value) {
        this.fieldValues = { ...this.fieldValues, [field]: value }
      }
    },
    clearValues () : void {
      console.log(this.$refs)
      Object.keys(this.$refs).forEach((key) => {
        this.$refs[key][0].clearValue()
      })
      this.fieldValues = {}
    },
    submit (): void {
      this.onSubmit(JSON.stringify(this.fieldValues))
    },
    camelCaseToWords (str: string) : string { // Converts camel case to readable format.
      return str.match(/^[a-z]+|[A-Z][a-z]*/g).map(function (x) {
        return x[0].toUpperCase() + x.substr(1).toLowerCase()
      }).join(' ')
    }
  }
}
