





























import router from '@/router'
// import store from '@/services/store'
import Vue from 'vue'
import Content from '../components/Content.vue'
import Form from '../components/Form.vue'
import SubmitTwoFactorCode from '../components/SubmitTwoFactorCode.vue'
import { useAuthStore } from 'pb-frontend-lib'

export default Vue.extend({
  name: 'Login',
  setup () {
    const authStore = useAuthStore()

    return { authStore }
  },
  data () {
    return {
      show2FA: false
    }
  },
  components: {
    Content,
    Form,
    SubmitTwoFactorCode
  },
  methods: {
    onSubmit (values) {
      fetch(process.env.VUE_APP_BACKEND_HOST + '/auth/login', { method: 'post', credentials: 'include', mode: 'cors', body: values, headers: { 'Content-Type': 'application/json' } }).then((response) => {
        if (response.status === 201) {
          response.json().then((json) => {
            if (json.data.message === 'Enter Authentication Code.') {
              this.show2FA = true
            } else {
              this.authStore.setExpiry(json.data.jwtExpiry)
              this.authStore.setUser(json.data.user)
              if (json.data.user.companies.length === 1) {
                this.authStore.setCompany(json.data.user.companies[0])
                //* Store local as the last known selected company (for persistance over refresh)
                localStorage.setItem('lastSelectedCompany', JSON.stringify(json.data.user.companies[0]))
              }

              // If found a backup (from local storage)
              if (this.authStore.selectedCompany) router.push(this.$route.query.redirect || '/')
              else router.push('/company-selection')
            }
          })
        } else {
          // console.log('Email or Password Incorrect, please try again.')
          console.log('response :>> ', response)
          this.$notify({
            group: 'main',
            title: 'Invalid Credentials',
            text: 'Email or Password Incorrect, please try again.',
            type: 'error'
          })
        }
      }).catch(err => {
        console.error(err)
        this.$notify({
          group: 'main',
          title: 'Error Signing In',
          text: 'There was an error signing you in. Please try again later.',
          type: 'error'
        })
      })
    },
    routerPush (path) {
      router.push(path)
    }
  }
})
