











































import Vue from 'vue'
import Content from '../components/Content.vue'
// import authStore from '@/services/authStore'
import router from '@/router'
import Modal from '../components/Modal.vue'
import Form from '../components/Form.vue'
import { useAuthStore } from 'pb-frontend-lib'

export default Vue.extend({
  name: 'CompanySelection',
  setup () {
    const authStore = useAuthStore()

    return { authStore }
  },
  components: {
    Content,
    Modal,
    Form
  },
  data () {
    return {
      createModalOpen: false,
      createCompanyAlert: false,
      createCompanyAlertType: 'default',
      createCompanyAlertText: ''
    }
  },
  computed: {
    selectedCompanyId () {
      if (this.authStore.selectedCompany && this.authStore.selectedCompany.id) return this.authStore.selectedCompany.id
      else return -1
    },
    companies () {
      return this.authStore.user.companies || []
    }
  },
  mounted: function () {
    if (this.authStore.selectedCompany?.id) {
      const currentCompanyId = this.authStore.selectedCompany.id
      const currentCompanyIndex = this.companies.findIndex(company => company.id === currentCompanyId)
      this.companies[currentCompanyIndex].current = true
    }
  },
  methods: {
    setCompany (company: Record<string, unknown>): void {
      this.authStore.setCompany(company)
      //* Store local as the last known selected company (for persistance over refresh)
      localStorage.setItem('lastSelectedCompany', JSON.stringify(company))
      router.push('/')
    },
    createCompany (fields: string): void {
      fetch(process.env.VUE_APP_BACKEND_HOST + '/companies', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: fields,
        credentials: 'include'
      }).then(async (response) => {
        if (response.status === 201) {
          this.createCompanyAlertText = 'Company Created!'
          this.createCompanyAlertType = 'success'
          this.createCompanyAlert = true
          await this.authStore.refreshToken(true)
          this.createModalOpen = false
        } else {
          this.createCompanyAlertText = 'Couldn\'t create company.'
          this.createCompanyAlertType = 'danger'
          this.createCompanyAlert = true
        }
      })
    }
  }
})
