









import Vue from 'vue'
import Content from '../components/Content.vue'
import SettingsNavigation from '../components/dashboard/settingsnavigation/SettingsNavigation.vue'

export default Vue.extend({
  components: {
    Content,
    SettingsNavigation
  },
  computed: {
  }
})
