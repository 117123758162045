

























































import { deletes, get } from '@/services/api'
import PDFList from '../components/PDFList.vue'
import Modal from '../components/Modal.vue'
import Content from '../components/Content.vue'
import Vue from 'vue'
import { useAuthStore } from 'pb-frontend-lib'
import { determinePDFStatus } from '@/services/pdfUtility'
import { AxiosResponse } from 'axios'

export default Vue.extend({
  // watch: {
  //   searchQuery (newVal, oldVal) {
  //     console.log('Search Changed: ', oldVal, newVal)
  //   }
  // },
  components: {
    Content,
    PDFList,
    Modal
  },
  data () {
    return {
      searchQuery: '',
      searchQueryOptions: 'all',
      searchOptionsModalOpen: false,
      searchResults: [],
      searching: false
    }
  },
  setup () {
    const authStore = useAuthStore()
    return { authStore }
  },
  computed: {

  },
  methods: {
    async search (query: string) {
      this.$router.push({ query: { query: query, search_options: this.searchQueryOptions } })
      this.searching = true
      const searchOptions = {
        email: query,
        emailSearchBy: this.searchQueryOptions
        // emailSearchBy: this.emailSearchOption
      }

      const url = '/pdfs/search?options=' + JSON.stringify(searchOptions)

      await get(url).then(res => {
        const pdfs = res.data
        pdfs.forEach((pdf) => {
          determinePDFStatus(pdf, this.authStore.user.id)
        })
        this.searchResults = pdfs
        this.searching = false
      })
    },
    async deletePdf (id) {
      try {
        const response: AxiosResponse = await deletes('/pdfs/' + id)

        if (response.status === 200) {
          this.$notify({
            group: 'main',
            title: 'PDF Deleted',
            text: 'The PDF has been successfully deleted',
            type: 'success'
          })
        }
      } catch (e) {
        if (e.response.status === 403) {
          this.$notify({
            group: 'main',
            title: 'Cannot Delete PDF',
            text: 'This PDF has already been signed by 1 or more user(s)',
            type: 'error'
          })
        }
      }

      this.search(this.searchQuery)
    }
  },
  mounted () {
    if (this.$router.currentRoute.query.search_options) {
      this.searchQueryOptions = this.$router.currentRoute.query.search_options
    }
    if (this.$router.currentRoute.query.query) {
      this.searchQuery = this.$router.currentRoute.query.query
      this.search(this.searchQuery)
    }
  }
})
